.balCard {
    background-color: #F1F5F9;
    padding: 12px;
    border-radius: 12px;
}
.share-cal .p-inputtext.p-component,.sub-cal .p-inputtext.p-component{
    border-right: none;
    padding: 8px 0px 8px 12px;
}
.share-cal .p-inputtext.p-component:hover,.sub-cal .p-inputtext.p-component:hover{
    border-color:#ced4da
}
.share-cal .p-inputtext.p-component~button,.sub-cal .p-inputtext.p-component~button{
    padding: 8px 0px 8px 0px;
}
.share-cal.p-inputtext.p-component~button:hover,.sub-cal .p-inputtext.p-component~button:hover{
    /* background-color: #fff; */
    outline: none;
    border-color: #ced4da;
}
.share-cal .p-inputtext.p-component~button:focus,.sub-cal .p-inputtext.p-component~button:focus{
    outline: none;
    box-shadow: none;
}
textarea::placeholder{
    color: var(--Light-Text-Secondary-Text, #CBD5E1);
}
.scroll::-webkit-scrollbar{
    display: none;
}
