.login-bg-primary {
    background-color: #F1F5F9;
}
.form {
    padding: 30px;
    width: 457px;
}
.link {
    color: #64748B !important;
}
