.p-dialog .p-dialog-header{
    padding:0px !important
}
.p-dialog .p-dialog-content{
    padding:0px !important
}
.p-dialog .p-dialog-footer{
    padding:0px !important
}
.p-treeselect .p-treeselect-label{
   padding:5px .75rem; 
}
::-webkit-scrollbar {
    width: 12px;
  }
::-webkit-scrollbar-track {
    background-color: #E2E8F0;
  }
  
::-webkit-scrollbar-thumb {
    background-color: #288d2c;
    border-radius: 6px;
  }
::-webkit-scrollbar-button { 
    display: none; 
} 
.p-dialog .p-dialog-footer button{
  width:138px !important
}

.p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button  {
  display: none;
}
.custom-column-style {
  width: 200px;
  /* Add more custom styles as needed */
}
