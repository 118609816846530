:root {
    --surface-a: #ffffff;
    --surface-b: #f8f9fa;
    --surface-c: #e9ecef;
    --surface-d: #d8dee4;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #495057;
    --text-color-secondary: #288d2c;
    --primary-color: #288d2c;
    --primary-color-text: #ffffff;
    --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding: 1rem;
    --inline-spacing: 0.5rem;
    --border-radius: 4px;
    --surface-ground: #f8f9fa;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dee2e6;
    --surface-hover: #e9ecef;
    --maskbg: rgba(0, 0, 0, 0.4);
    --focus-ring: 0 0 0 0.2rem #b3cbee;
    color-scheme: light
}

* {
    box-sizing: border-box
}

body .ui-widget {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    text-decoration: none
}

body .ui-icon {
    font-size: 1rem;
    display: inline-block;
    vertical-align: baseline;
    margin: 0;
    position: static;
    text-indent: 0;
    overflow: visible;
    background-repeat: no-repeat
}

body .pi {
    font-size: 1rem
}

body .ui-widget-overlay {
    -webkit-animation-name: modal-in;
    animation-name: modal-in;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
    animation-fill-mode: forwards
}

body .ui-helper-reset {
    line-height: normal
}

body .ui-state-disabled {
    opacity: 0
}

body .ui-widget-overlay {
    background: rgba(0, 0, 0, .4)
}

body .ui-resizable-handle {
    position: absolute
}

.ui-resizable {
    display: flex;
    flex-direction: column
}

.ui-resizable .ui-widget-content {
    flex-grow: 1
}

:root {
    --blue-50: #f4fafe;
    --blue-100: #cae6fc;
    --blue-200: #a0d2fa;
    --blue-300: #75bef8;
    --blue-400: #4baaf5;
    --blue-500: #288d2c;
    --blue-600: #1c80cf;
    --blue-700: #1769aa;
    --blue-800: #125386;
    --blue-900: #0d3c61;
    --green-50: #f2f6fc;
    --green-100: #c2d5f1;
    --green-200: #91b4e6;
    --green-300: #6193dc;
    --green-400: #3071d1;
    --green-500: #288d2c;
    --green-600: #0044a8;
    --green-700: #00388b;
    --green-800: #002c6d;
    --green-900: #00204f;
    --yellow-50: #fffcf5;
    --yellow-100: #fef0cd;
    --yellow-200: #fde4a5;
    --yellow-300: #fdd87d;
    --yellow-400: #fccc55;
    --yellow-500: #fbc02d;
    --yellow-600: #d5a326;
    --yellow-700: #b08620;
    --yellow-800: #8a6a19;
    --yellow-900: #644d12;
    --cyan-50: #f2fcfd;
    --cyan-100: #c2eff5;
    --cyan-200: #91e2ed;
    --cyan-300: #61d5e4;
    --cyan-400: #30c9dc;
    --cyan-500: #00bcd4;
    --cyan-600: #00a0b4;
    --cyan-700: #008494;
    --cyan-800: #006775;
    --cyan-900: #004b55;
    --pink-50: #fef4f7;
    --pink-100: #fac9da;
    --pink-200: #f69ebc;
    --pink-300: #f1749e;
    --pink-400: #ed4981;
    --pink-500: #e91e63;
    --pink-600: #c61a54;
    --pink-700: #a31545;
    --pink-800: #801136;
    --pink-900: #5d0c28;
    --indigo-50: #f5f6fb;
    --indigo-100: #d1d5ed;
    --indigo-200: #acb4df;
    --indigo-300: #8893d1;
    --indigo-400: #6372c3;
    --indigo-500: #3f51b5;
    --indigo-600: #36459a;
    --indigo-700: #2c397f;
    --indigo-800: #232d64;
    --indigo-900: #192048;
    --teal-50: #f2faf9;
    --teal-100: #c2e6e2;
    --teal-200: #91d2cc;
    --teal-300: #61beb5;
    --teal-400: #30aa9f;
    --teal-500: #009688;
    --teal-600: #008074;
    --teal-700: #00695f;
    --teal-800: #00534b;
    --teal-900: #003c36;
    --orange-50: #fff8f2;
    --orange-100: #fde0c2;
    --orange-200: #fbc791;
    --orange-300: #f9ae61;
    --orange-400: #f79530;
    --orange-500: #f57c00;
    --orange-600: #d06900;
    --orange-700: #ac5700;
    --orange-800: #874400;
    --orange-900: #623200;
    --bluegray-50: #f7f9f9;
    --bluegray-100: #d9e0e3;
    --bluegray-200: #bbc7cd;
    --bluegray-300: #9caeb7;
    --bluegray-400: #7e96a1;
    --bluegray-500: #607d8b;
    --bluegray-600: #526a76;
    --bluegray-700: #435861;
    --bluegray-800: #35454c;
    --bluegray-900: #263238;
    --purple-50: #faf4fb;
    --purple-100: #e7cbec;
    --purple-200: #d4a2dd;
    --purple-300: #c279ce;
    --purple-400: #af50bf;
    --purple-500: #9c27b0;
    --purple-600: #852196;
    --purple-700: #6d1b7b;
    --purple-800: #561561;
    --purple-900: #3e1046;
    --red-50: #fff5f5;
    --red-100: #ffd1ce;
    --red-200: #ffada7;
    --red-300: #ff8980;
    --red-400: #ff6459;
    --red-500: #ff4032;
    --red-600: #d9362b;
    --red-700: #b32d23;
    --red-800: #8c231c;
    --red-900: #661a14;
    --primary-50: #f2f6fc;
    --primary-100: #c2d5f1;
    --primary-200: #91b4e6;
    --primary-300: #6193dc;
    --primary-400: #3071d1;
    --primary-500: #288d2c;
    --primary-600: #0044a8;
    --primary-700: #00388b;
    --primary-800: #002c6d;
    --primary-900: #00204f
}

body .ui-button {
    background: #288d2c;
    color: #fff;
    border: 1px solid #288d2c;
    margin: 0;
    outline: 0 none;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-button.ui-state-hover {
    background: #0048b2;
    border-color: #0048b2;
    color: #fff
}

body .ui-button.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2em #82b5ff
}

body .ui-button.ui-state-active,
body .ui-button.ui-state-down {
    background: #00409e;
    border-color: #00409e;
    color: #fff
}

body .ui-button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #288d2c;
    border: 1px solid
}

body .ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(0, 80, 198, .04);
    color: #288d2c;
    border: 1px solid
}

body .ui-button.ui-button-outlined.ui-state-active,
body .ui-button.ui-button-outlined.ui-state-down {
    background: rgba(0, 80, 198, .16);
    color: #288d2c;
    border: 1px solid
}

body .ui-button.ui-button-outlined.ui-button-plain {
    color: #288d2c;
    border-color: #288d2c
}

body .ui-button.ui-button-outlined.ui-button-plain.ui-state-hover {
    background: #e9ecef;
    color: #288d2c
}

body .ui-button.ui-button-outlined.ui-button-plain.ui-state-active,
body .ui-button.ui-button-outlined.ui-button-plain.ui-state-down {
    background: #d8dee4;
    color: #288d2c
}

body .ui-button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #288d2c;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-flat.ui-state-hover {
    background: rgba(0, 80, 198, .04);
    color: #288d2c;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-flat.ui-state-active,
body .ui-button.ui-button-flat.ui-state-down {
    background: rgba(0, 80, 198, .16);
    color: #288d2c;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-flat.ui-button-plain {
    color: #288d2c
}

body .ui-button.ui-button-flat.ui-button-plain.ui-state-hover {
    background: #e9ecef;
    color: #288d2c
}

body .ui-button.ui-button-flat.ui-button-plain.ui-state-active,
body .ui-button.ui-button-flat.ui-button-plain.ui-state-down {
    background: #d8dee4;
    color: #288d2c
}

body .ui-button.ui-button-text-only .ui-button-text {
    padding: .5rem 1rem
}

body .ui-button.ui-button-text-icon-left .ui-button-text {
    padding: .5rem 1rem .5rem 2rem
}

body .ui-button.ui-button-text-icon-left .ui-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5rem;
    left: .5rem
}

body .ui-button.ui-button-text-icon-right .ui-button-text {
    padding: .5rem 2rem .5rem 1rem
}

body .ui-button.ui-button-text-icon-right .ui-icon {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-top: -0.5rem;
    right: .5rem
}

body .ui-button.ui-button-icon-only {
    width: 2.357rem
}

body .ui-button.ui-button-icon-only .ui-button-text {
    padding: .5rem
}

body .ui-button.ui-button-icon-only .ui-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5rem;
    margin-left: -0.5rem
}

body .ui-button.ui-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

body .ui-button.rounded-button {
    border-radius: 2rem
}

body .ui-button.rounded-button.ui-button-icon-only {
    border-radius: 50%;
    height: 2.357rem
}

body .ui-button .ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-button .ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-selectbooleanbutton,
body .ui-selectonebutton>.ui-button,
body .ui-selectmanybutton>.ui-button {
    background: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    outline: 0 none;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-selectbooleanbutton .ui-icon,
body .ui-selectonebutton>.ui-button .ui-icon,
body .ui-selectmanybutton>.ui-button .ui-icon {
    color: #288d2c
}

body .ui-selectbooleanbutton.ui-state-hover,
body .ui-selectonebutton>.ui-button.ui-state-hover,
body .ui-selectmanybutton>.ui-button.ui-state-hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057
}

body .ui-selectbooleanbutton.ui-state-hover .ui-icon,
body .ui-selectonebutton>.ui-button.ui-state-hover .ui-icon,
body .ui-selectmanybutton>.ui-button.ui-state-hover .ui-icon {
    color: #288d2c
}

body .ui-selectbooleanbutton.ui-state-active,
body .ui-selectonebutton>.ui-button.ui-state-active,
body .ui-selectmanybutton>.ui-button.ui-state-active {
    background: #288d2c;
    border-color: #288d2c;
    color: #fff
}

body .ui-selectbooleanbutton.ui-state-active .ui-icon,
body .ui-selectonebutton>.ui-button.ui-state-active .ui-icon,
body .ui-selectmanybutton>.ui-button.ui-state-active .ui-icon {
    color: #fff
}

body .ui-selectbooleanbutton.ui-state-active:not(.ui-state-disabled):hover,
body .ui-selectonebutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover,
body .ui-selectmanybutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover {
    background: #0048b2;
    border-color: #0048b2;
    color: #fff
}

body .ui-selectbooleanbutton.ui-state-active:not(.ui-state-disabled):hover .ui-icon,
body .ui-selectonebutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-icon,
body .ui-selectmanybutton>.ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-icon {
    color: #fff
}

body .ui-selectonebutton>.ui-button,
body .ui-selectmanybutton>.ui-button {
    border-radius: 0
}

body .ui-selectonebutton>.ui-button:first-child,
body .ui-selectmanybutton>.ui-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-selectonebutton>.ui-button:last-child,
body .ui-selectmanybutton>.ui-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-selectonebutton>.ui-button.ui-state-focus,
body .ui-selectmanybutton>.ui-button.ui-state-focus {
    z-index: 1
}

body .ui-selectonebutton>.ui-button:not(:last-child),
body .ui-selectmanybutton>.ui-button:not(:last-child) {
    border-right-width: 0
}

@media(max-width: 640px) {

    body .ui-selectonebutton>div.ui-button:not(:last-child),
    body .ui-selectmanybutton>div.ui-button:not(:last-child) {
        border-bottom-width: 0;
        border-right-width: 1px
    }

    body .ui-selectonebutton>div.ui-button:first-child,
    body .ui-selectmanybutton>div.ui-button:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0
    }

    body .ui-selectonebutton>div.ui-button:last-child,
    body .ui-selectmanybutton>div.ui-button:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-width: 1px
    }
}

@media(max-width: 640px) {
    body .ui-dataview-layout-options>div.ui-button:not(:last-child) {
        border-bottom-width: 1px;
        border-right-width: 0
    }

    body .ui-dataview-layout-options>div.ui-button:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    body .ui-dataview-layout-options>div.ui-button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px
    }
}

body .ui-button-group .ui-button {
    margin: 0;
    border-radius: 0
}

body .ui-button-group .ui-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-button-group .ui-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-button-group .ui-button.ui-state-focus {
    z-index: 1
}

body .ui-button.ui-button-secondary,
body .ui-splitbutton.ui-button-secondary>.ui-button,
body .ui-menubutton.ui-button-secondary>.ui-button {
    background: #6d35d0;
    color: #fff;
    border: 1px solid #6d35d0
}

body .ui-button.ui-button-secondary.ui-state-hover,
body .ui-button.ui-button-secondary:not(:disabled):hover,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-state-hover,
body .ui-splitbutton.ui-button-secondary>.ui-button:not(:disabled):hover,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-state-hover,
body .ui-menubutton.ui-button-secondary>.ui-button:not(:disabled):hover {
    background: #612cbf;
    border-color: #612cbf;
    color: #fff
}

body .ui-button.ui-button-secondary.ui-state-focus,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-state-focus,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #c5aeec
}

body .ui-button.ui-button-secondary.ui-state-active,
body .ui-button.ui-button-secondary:active,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-state-active,
body .ui-splitbutton.ui-button-secondary>.ui-button:active,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-state-active,
body .ui-menubutton.ui-button-secondary>.ui-button:active {
    background: #5627a9;
    border-color: #5627a9;
    color: #fff
}

body .ui-button.ui-button-secondary.ui-button-outlined,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-outlined,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #6d35d0;
    border: 1px solid
}

body .ui-button.ui-button-secondary.ui-button-outlined:enabled:hover,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-outlined:enabled:hover,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-button-outlined:enabled:hover {
    background: rgba(109, 53, 208, .04);
    color: #6d35d0;
    border: 1px solid
}

body .ui-button.ui-button-secondary.ui-button-outlined:enabled:active,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-outlined:enabled:active,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-button-outlined:enabled:active {
    background: rgba(109, 53, 208, .16);
    color: #6d35d0;
    border: 1px solid
}

body .ui-button.ui-button-secondary.ui-button-flat,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-flat,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #6d35d0;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-secondary.ui-button-flat:enabled:hover,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-flat:enabled:hover,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-button-flat:enabled:hover {
    background: rgba(109, 53, 208, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #6d35d0
}

body .ui-button.ui-button-secondary.ui-button-flat:enabled:active,
body .ui-splitbutton.ui-button-secondary>.ui-button.ui-button-flat:enabled:active,
body .ui-menubutton.ui-button-secondary>.ui-button.ui-button-flat:enabled:active {
    background: rgba(109, 53, 208, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #6d35d0
}

body .ui-button.ui-button-success,
body .ui-splitbutton.ui-button-success>.ui-button,
body .ui-menubutton.ui-button-success>.ui-button {
    background: #689f38;
    color: #fff;
    border: 1px solid #689f38
}

body .ui-button.ui-button-success.ui-state-hover,
body .ui-splitbutton.ui-button-success>.ui-button.ui-state-hover,
body .ui-menubutton.ui-button-success>.ui-button.ui-state-hover {
    background: #5e8f32;
    color: #fff;
    border-color: #5e8f32
}

body .ui-button.ui-button-success.ui-state-focus,
body .ui-splitbutton.ui-button-success>.ui-button.ui-state-focus,
body .ui-menubutton.ui-button-success>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #c2e0a8
}

body .ui-button.ui-button-success.ui-state-active,
body .ui-splitbutton.ui-button-success>.ui-button.ui-state-active,
body .ui-menubutton.ui-button-success>.ui-button.ui-state-active {
    background: #537f2d;
    color: #fff
}

body .ui-button.ui-button-success.ui-button-outlined,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-outlined,
body .ui-menubutton.ui-button-success>.ui-button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #689f38;
    border: 1px solid
}

body .ui-button.ui-button-success.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-outlined.ui-state-hover,
body .ui-menubutton.ui-button-success>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(104, 159, 56, .04);
    color: #689f38;
    border: 1px solid
}

body .ui-button.ui-button-success.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-outlined.ui-state-active,
body .ui-menubutton.ui-button-success>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(104, 159, 56, .16);
    color: #689f38;
    border: 1px solid
}

body .ui-button.ui-button-success.ui-button-flat,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-flat,
body .ui-menubutton.ui-button-success>.ui-button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #689f38;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-success.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-flat.ui-state-hover,
body .ui-menubutton.ui-button-success>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(104, 159, 56, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #689f38
}

body .ui-button.ui-button-success.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-success>.ui-button.ui-button-flat.ui-state-active,
body .ui-menubutton.ui-button-success>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(104, 159, 56, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #689f38
}

body .ui-button.ui-button-warning,
body .ui-splitbutton.ui-button-warning>.ui-button,
body .ui-menubutton.ui-button-warning>.ui-button {
    background: #fbc02d;
    color: #212529;
    border: 1px solid #fbc02d
}

body .ui-button.ui-button-warning.ui-state-hover,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-state-hover,
body .ui-menubutton.ui-button-warning>.ui-button.ui-state-hover {
    background: #fab710;
    color: #212529;
    border-color: #fab710
}

body .ui-button.ui-button-warning.ui-state-focus,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-state-focus,
body .ui-menubutton.ui-button-warning>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #fde6ab
}

body .ui-button.ui-button-warning.ui-state-active,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-state-active,
body .ui-menubutton.ui-button-warning>.ui-button.ui-state-active {
    background: #e8a704;
    color: #212529;
    border-color: #e8a704
}

body .ui-button.ui-button-warning.ui-button-outlined,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-outlined,
body .ui-menubutton.ui-button-warning>.ui-button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #fbc02d;
    border: 1px solid
}

body .ui-button.ui-button-warning.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-outlined.ui-state-hover,
body .ui-menubutton.ui-button-warning>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(251, 192, 45, .04);
    color: #fbc02d;
    border: 1px solid
}

body .ui-button.ui-button-warning.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-outlined.ui-state-active,
body .ui-menubutton.ui-button-warning>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(251, 192, 45, .16);
    color: #fbc02d;
    border: 1px solid
}

body .ui-button.ui-button-warning.ui-button-flat,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-flat,
body .ui-menubutton.ui-button-warning>.ui-button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #fbc02d;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-warning.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-flat.ui-state-hover,
body .ui-menubutton.ui-button-warning>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(251, 192, 45, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #fbc02d
}

body .ui-button.ui-button-warning.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-warning>.ui-button.ui-button-flat.ui-state-active,
body .ui-menubutton.ui-button-warning>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(251, 192, 45, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #fbc02d
}

body .ui-button.ui-button-help,
body .ui-splitbutton.ui-button-help>.ui-button,
body .ui-menubutton.ui-button-help>.ui-button {
    background: #3098e8;
    color: #fff;
    border: 1px solid #3098e8
}

body .ui-button.ui-button-help.ui-state-hover,
body .ui-splitbutton.ui-button-help>.ui-button.ui-state-hover,
body .ui-menubutton.ui-button-help>.ui-button.ui-state-hover {
    background: #198be3;
    color: #fff;
    border-color: #198be3
}

body .ui-button.ui-button-help.ui-state-focus,
body .ui-splitbutton.ui-button-help>.ui-button.ui-state-focus,
body .ui-menubutton.ui-button-help>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #acd6f6
}

body .ui-button.ui-button-help.ui-state-active,
body .ui-splitbutton.ui-button-help>.ui-button.ui-state-active,
body .ui-menubutton.ui-button-help>.ui-button.ui-state-active {
    background: #167cca;
    color: #fff;
    border-color: #167cca
}

body .ui-button.ui-button-help.ui-button-outlined,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-outlined,
body .ui-menubutton.ui-button-help>.ui-button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #3098e8;
    border: 1px solid
}

body .ui-button.ui-button-help.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-outlined.ui-state-hover,
body .ui-menubutton.ui-button-help>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(48, 152, 232, .04);
    color: #3098e8;
    border: 1px solid
}

body .ui-button.ui-button-help.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-outlined.ui-state-active,
body .ui-menubutton.ui-button-help>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(48, 152, 232, .16);
    color: #3098e8;
    border: 1px solid
}

body .ui-button.ui-button-help.ui-button-flat,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-flat,
body .ui-menubutton.ui-button-help>.ui-button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #3098e8;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-help.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-flat.ui-state-hover,
body .ui-menubutton.ui-button-help>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(48, 152, 232, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #3098e8
}

body .ui-button.ui-button-help.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-help>.ui-button.ui-button-flat.ui-state-active,
body .ui-menubutton.ui-button-help>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(48, 152, 232, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #3098e8
}

body .ui-button.ui-button-info,
body .ui-splitbutton.ui-button-info>.ui-button,
body .ui-menubutton.ui-button-info>.ui-button {
    background: #787878;
    color: #fff;
    border: 1px solid #787878
}

body .ui-button.ui-button-info.ui-state-hover,
body .ui-splitbutton.ui-button-info>.ui-button.ui-state-hover,
body .ui-menubutton.ui-button-info>.ui-button.ui-state-hover {
    background: #6c6c6c;
    color: #fff;
    border-color: #6c6c6c
}

body .ui-button.ui-button-info.ui-state-focus,
body .ui-splitbutton.ui-button-info>.ui-button.ui-state-focus,
body .ui-menubutton.ui-button-info>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #c9c9c9
}

body .ui-button.ui-button-info.ui-state-active,
body .ui-splitbutton.ui-button-info>.ui-button.ui-state-active,
body .ui-menubutton.ui-button-info>.ui-button.ui-state-active {
    background: #606060;
    color: #fff;
    border-color: #606060
}

body .ui-button.ui-button-info.ui-button-outlined,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-outlined,
body .ui-menubutton.ui-button-info>.ui-button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #787878;
    border: 1px solid
}

body .ui-button.ui-button-info.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-outlined.ui-state-hover,
body .ui-menubutton.ui-button-info>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(120, 120, 120, .04);
    color: #787878;
    border: 1px solid
}

body .ui-button.ui-button-info.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-outlined.ui-state-active,
body .ui-menubutton.ui-button-info>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(120, 120, 120, .16);
    color: #787878;
    border: 1px solid
}

body .ui-button.ui-button-info.ui-button-flat,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-flat,
body .ui-menubutton.ui-button-info>.ui-button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #787878;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-info.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-flat.ui-state-hover,
body .ui-menubutton.ui-button-info>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(120, 120, 120, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #787878
}

body .ui-button.ui-button-info.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-info>.ui-button.ui-button-flat.ui-state-active,
body .ui-menubutton.ui-button-info>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(120, 120, 120, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #787878
}

body .ui-button.ui-button-danger,
body .ui-splitbutton.ui-button-danger>.ui-button,
body .ui-menubutton.ui-button-danger>.ui-button {
    background: #d32f2f;
    color: #fff;
    border: 1px solid #d32f2f
}

body .ui-button.ui-button-danger.ui-state-hover,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-state-hover,
body .ui-menubutton.ui-button-danger>.ui-button.ui-state-hover {
    background: #c02929;
    color: #fff;
    border-color: #c02929
}

body .ui-button.ui-button-danger.ui-state-focus,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-state-focus,
body .ui-menubutton.ui-button-danger>.ui-button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #edacac
}

body .ui-button.ui-button-danger.ui-state-active,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-state-active,
body .ui-menubutton.ui-button-danger>.ui-button.ui-state-active {
    background: #aa2424;
    color: #fff;
    border-color: #aa2424
}

body .ui-button.ui-button-danger.ui-button-outlined,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-outlined,
body .ui-menubutton.ui-button-danger>.ui-button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #d32f2f;
    border: 1px solid
}

body .ui-button.ui-button-danger.ui-button-outlined.ui-state-hover,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-outlined.ui-state-hover,
body .ui-menubutton.ui-button-danger>.ui-button.ui-button-outlined.ui-state-hover {
    background: rgba(211, 47, 47, .04);
    color: #d32f2f;
    border: 1px solid
}

body .ui-button.ui-button-danger.ui-button-outlined.ui-state-active,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-outlined.ui-state-active,
body .ui-menubutton.ui-button-danger>.ui-button.ui-button-outlined.ui-state-active {
    background: rgba(211, 47, 47, .16);
    color: #d32f2f;
    border: 1px solid
}

body .ui-button.ui-button-danger.ui-button-flat,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-flat,
body .ui-menubutton.ui-button-danger>.ui-button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #d32f2f;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-button.ui-button-danger.ui-button-flat.ui-state-hover,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-flat.ui-state-hover,
body .ui-menubutton.ui-button-danger>.ui-button.ui-button-flat.ui-state-hover {
    background: rgba(211, 47, 47, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #d32f2f
}

body .ui-button.ui-button-danger.ui-button-flat.ui-state-active,
body .ui-splitbutton.ui-button-danger>.ui-button.ui-button-flat.ui-state-active,
body .ui-menubutton.ui-button-danger>.ui-button.ui-button-flat.ui-state-active {
    background: rgba(211, 47, 47, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #d32f2f
}

body .ui-commandlink,
body .ui-link {
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-commandlink:hover,
body .ui-link:hover {
    color: #0048b2;
    text-decoration: underline
}

body .ui-commandlink:active,
body .ui-link:active {
    color: #00409e
}

body .ui-splitbutton {
    padding: 0
}

body .ui-splitbutton .ui-button:first-child {
    position: relative;
    margin: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

body .ui-splitbutton .ui-button:first-child.ui-state-focus {
    z-index: 1
}

body .ui-splitbutton .ui-splitbuttonmenu {
    min-width: 100%
}

body .ui-splitbutton .ui-button.ui-splitbutton-menubutton {
    cursor: pointer;
    height: auto;
    padding: 0;
    position: relative;
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-splitbutton .ui-button.ui-splitbutton-menubutton .ui-icon.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-splitbutton .ui-button.ui-splitbutton-menubutton .ui-icon.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container {
    padding: .5rem 1rem;
    margin: 0;
    border-bottom: 0 none;
    background: #f8f9fa;
    color: #495057
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container .ui-splitbuttonmenu-filter.ui-inputfield {
    padding: .5rem .5rem;
    width: 100%;
    padding-left: 2rem
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    right: auto;
    bottom: auto;
    margin-top: -0.5rem;
    color: #288d2c
}

body .ui-splitbuttonmenu .ui-splitbuttonmenu-filter-container .ui-icon:before {
    content: ""
}

body .ui-speeddial-button.ui-button.ui-button-icon-only {
    width: 4rem;
    height: 4rem
}

body .ui-speeddial-button.ui-button.ui-button-icon-only .ui-button-icon {
    font-size: 1.3rem
}

body .ui-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #495057;
    color: #fff
}

body .ui-speeddial-action:hover {
    background: #343a40;
    color: #fff
}

body .ui-speeddial-direction-up .ui-speeddial-item {
    margin: .25rem 0
}

body .ui-speeddial-direction-up .ui-speeddial-item:first-child {
    margin-bottom: .5rem
}

body .ui-speeddial-direction-down .ui-speeddial-item {
    margin: .25rem 0
}

body .ui-speeddial-direction-down .ui-speeddial-item:first-child {
    margin-top: .5rem
}

body .ui-speeddial-direction-left .ui-speeddial-item {
    margin: 0 .25rem
}

body .ui-speeddial-direction-left .ui-speeddial-item:first-child {
    margin-right: .5rem
}

body .ui-speeddial-direction-right .ui-speeddial-item {
    margin: 0 .25rem
}

body .ui-speeddial-direction-right .ui-speeddial-item:first-child {
    margin-left: .5rem
}

body .ui-speeddial-circle .ui-speeddial-item,
body .ui-speeddial-semi-circle .ui-speeddial-item,
body .ui-speeddial-quarter-circle .ui-speeddial-item {
    margin: 0
}

body .ui-speeddial-circle .ui-speeddial-item:first-child,
body .ui-speeddial-circle .ui-speeddial-item:last-child,
body .ui-speeddial-semi-circle .ui-speeddial-item:first-child,
body .ui-speeddial-semi-circle .ui-speeddial-item:last-child,
body .ui-speeddial-quarter-circle .ui-speeddial-item:first-child,
body .ui-speeddial-quarter-circle .ui-speeddial-item:last-child {
    margin: 0
}

body .ui-speeddial-mask {
    background-color: rgba(0, 0, 0, .4)
}

body .ui-carousel .ui-carousel-content .ui-carousel-prev,
body .ui-carousel .ui-carousel-content .ui-carousel-next {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none;
    margin: .5rem
}

body .ui-carousel .ui-carousel-content .ui-carousel-prev:hover,
body .ui-carousel .ui-carousel-content .ui-carousel-next:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-carousel .ui-carousel-content .ui-carousel-prev:focus,
body .ui-carousel .ui-carousel-content .ui-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-carousel .ui-carousel-indicators {
    padding: 1rem
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator {
    margin-right: .5rem;
    margin-bottom: .5rem
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator button {
    background-color: #e9ecef;
    width: 2rem;
    height: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    border-radius: 0
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator button:hover {
    background: #d8dee4
}

body .ui-carousel .ui-carousel-indicators .ui-carousel-indicator.ui-state-highlight button {
    background: #288d2c;
    color: #fff
}

body .ui-chronoline .ui-chronoline-event-marker {
    border: 2px solid #288d2c;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #fff
}

body .ui-chronoline .ui-chronoline-event-connector {
    background-color: #d8dee4
}

body .ui-chronoline.ui-chronoline-vertical .ui-chronoline-event-opposite,
body .ui-chronoline.ui-chronoline-vertical .ui-chronoline-event-content {
    padding: 0 1rem
}

body .ui-chronoline.ui-chronoline-vertical .ui-chronoline-event-connector {
    width: 2px
}

body .ui-chronoline.ui-chronoline-horizontal .ui-chronoline-event-opposite,
body .ui-chronoline.ui-chronoline-horizontal .ui-chronoline-event-content {
    padding: 1rem 0
}

body .ui-chronoline.ui-chronoline-horizontal .ui-chronoline-event-connector {
    height: 2px
}

body .ui-datagrid .ui-datagrid-header,
body .ui-datagrid .ui-datagrid-footer {
    background: #f8f9fa;
    border: 1px solid #d8dee4;
    color: #495057;
    padding: 1rem;
    font-weight: 700
}

body .ui-datagrid .ui-datagrid-header {
    border-bottom: 0 none
}

body .ui-datagrid .ui-datagrid-footer {
    border-top: 0 none
}

body .ui-datagrid .ui-datagrid-content {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-datagrid .ui-paginator {
    padding: 1rem
}

body .ui-datagrid .ui-paginator.ui-paginator-top {
    border-bottom: 0 none
}

body .ui-datagrid .ui-paginator.ui-paginator-bottom {
    border-top: 0 none
}

body .ui-datalist .ui-datalist-header,
body .ui-datalist .ui-datalist-footer {
    background: #f8f9fa;
    border: 1px solid #d8dee4;
    color: #495057;
    padding: 1rem;
    font-weight: 700
}

body .ui-datalist .ui-datalist-header {
    border-bottom: 0 none
}

body .ui-datalist .ui-datalist-footer {
    border-top: 0 none
}

body .ui-datalist .ui-datalist-content {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-datalist .ui-paginator {
    padding: 1rem
}

body .ui-datalist .ui-paginator.ui-paginator-top {
    border-bottom: 0 none
}

body .ui-datalist .ui-paginator.ui-paginator-bottom {
    border-top: 0 none
}

body .ui-datascroller .ui-datascroller-header,
body .ui-datascroller .ui-datascroller-footer {
    background: #f8f9fa;
    border: 1px solid #d8dee4;
    color: #495057;
    padding: 1rem;
    font-weight: 700
}

body .ui-datascroller .ui-datascroller-header {
    border-bottom: 0 none
}

body .ui-datascroller .ui-datascroller-footer {
    border-top: 0 none
}

body .ui-datascroller .ui-datascroller-content {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-datatable .ui-datatable-header {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    color: #495057;
    padding: 1rem 1rem;
    font-weight: 600;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-datatable .ui-datatable-footer {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    color: #495057;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-datatable .ui-datatable-header {
    text-align: left
}

body .ui-datatable .ui-datatable-footer {
    text-align: left
}

body .ui-datatable .ui-paginator {
    padding: 1rem
}

body .ui-datatable .ui-paginator.ui-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0
}

body .ui-datatable .ui-paginator.ui-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0
}

body .ui-datatable thead th {
    background: #f8f9fa;
    color: #495057;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    text-align: left;
    box-sizing: content-box;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-datatable thead th .ui-column-title {
    font-weight: 700;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon {
    margin: 0 0 0 .5rem
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s:before {
    content: ""
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n:before {
    content: ""
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    vertical-align: middle
}

body .ui-datatable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-datatable thead th .ui-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #fff;
    background: #288d2c;
    margin-left: .5rem;
    font-size: .75rem
}

body .ui-datatable thead th.ui-sortable-column.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .2rem #b3cbee
}

body .ui-datatable thead th.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-datatable thead th.ui-state-active {
    background: #f8f9fa;
    color: #288d2c
}

body .ui-datatable thead th.ui-state-active.ui-state-hover {
    background: #e9ecef;
    color: #288d2c
}

body .ui-datatable .ui-datatable-data>tr {
    background: #fff;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-datatable .ui-datatable-data>tr>td {
    padding: 1rem 1rem;
    border: 1px solid rgba(0, 0, 0, .08);
    border-width: 0 0 1px 0
}

body .ui-datatable .ui-datatable-data>tr>td.ui-datatable-subtable-header {
    background: #f8f9fa;
    border: 1px solid #d8dee4;
    color: #495057;
    font-weight: 700
}

body .ui-datatable .ui-datatable-data>tr.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr.ui-state-highlight .ui-row-toggler {
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr.ui-state-highlight .ui-row-editor>a {
    color: #495057
}

body .ui-datatable .ui-datatable-data>tr.ui-datatable-selectable {
    cursor: pointer
}

body .ui-datatable .ui-datatable-data>tr.ui-datatable-selectable>.ui-grouped-column {
    background: #fff;
    color: #495057;
    cursor: default
}

body .ui-datatable .ui-datatable-data>tr.ui-rowgroup-header,
body .ui-datatable .ui-datatable-data>tr.ui-datatable-summaryrow {
    font-weight: 700
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor>a.ui-row-editor-check {
    margin-right: .5rem
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil:before {
    content: ""
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-check {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-check:before {
    content: ""
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-close {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid #288d2c;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-slider .p-slider-range {
    background: #288d2c;
}

body .ui-datatable .ui-datatable-data>tr .ui-row-editor .ui-icon.ui-icon-close:before {
    content: ""
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-e:before {
    content: ""
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datatable .ui-datatable-data>tr .ui-row-toggler.ui-icon-circle-triangle-s:before {
    content: ""
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler {
    margin-right: 1rem
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-e:before {
    content: ""
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datatable .ui-datatable-data>tr .ui-rowgroup-toggler .ui-rowgroup-toggler-icon.ui-icon-circle-triangle-s:before {
    content: ""
}

body .ui-datatable .ui-datatable-data>tr.ui-datatable-rowordering {
    background: #e6eef9
}

body .ui-datatable .ui-column-resizer-helper {
    background: #288d2c
}

body .ui-datatable tfoot td {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    color: #495057;
    padding: 1rem 1rem;
    font-weight: 700;
    text-align: left;
    box-sizing: content-box
}

body .ui-datatable>.ui-icon-arrowthick-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    display: none !important
}

body .ui-datatable>.ui-icon-arrowthick-1-s:before {
    content: ""
}

body .ui-datatable>.ui-icon-arrowthick-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    padding-top: 1.5rem !important
}

body .ui-datatable>.ui-icon-arrowthick-1-n:before {
    content: ""
}

body .ui-datatable .ui-datatable-scrollable-body {
    outline: 0 none
}

body .ui-datatable .ui-datatable-scrollable-header,
body .ui-datatable .ui-datatable-scrollable-footer {
    background: #f8f9fa
}

body .ui-datatable .ui-selection-column .ui-chkbox-all {
    margin: 0 auto;
    width: 1.5rem;
    height: 1.5rem
}

body .ui-datatable.ui-datatable-striped .ui-datatable-data>tr.ui-datatable-odd {
    background: #fbfcfc
}

body .ui-datatable.ui-datatable-striped .ui-datatable-data>tr.ui-datatable-odd.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

.p-button {
    background: #288d2c;
    border: 1px solid #288d2c;
}

body .ui-datatable.ui-datatable-striped .ui-datatable-data>tr.ui-datatable-odd.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-datatable.ui-datatable-sm thead>tr>th {
    padding: .5rem .5rem
}

body .ui-datatable.ui-datatable-sm .ui-datatable-scrollable-theadclone>tr>th {
    padding-top: 0;
    padding-bottom: 0
}

body .ui-datatable.ui-datatable-sm .ui-datatable-data>tr>td {
    padding: .5rem .5rem
}

body .ui-datatable.ui-datatable-sm tfoot>tr>td {
    padding: .5rem .5rem
}

body .ui-datatable.ui-datatable-lg thead>tr>th {
    padding: 1.25rem 1.25rem
}

body .ui-datatable.ui-datatable-lg .ui-datatable-scrollable-theadclone>tr>th {
    padding-top: 0;
    padding-bottom: 0
}

body .ui-datatable.ui-datatable-lg .ui-datatable-data>tr>td {
    padding: 1.25rem 1.25rem
}

body .ui-datatable.ui-datatable-lg tfoot>tr>td {
    padding: 1.25rem 1.25rem
}

body .ui-datatable.ui-datatable-gridlines .ui-datatable-header {
    border-width: 1px
}

body .ui-datatable.ui-datatable-gridlines thead>tr>th {
    border-width: 1px
}

body .ui-datatable.ui-datatable-gridlines .ui-datatable-data>tr>td {
    border-width: 1px
}

body .ui-datatable.ui-datatable-gridlines .ui-datatable-scrollable-theadclone>tr>th {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0 none;
    border-top: 0 none
}

body .ui-datatable.ui-datatable-gridlines tfoot>tr>td {
    border-width: 1px
}

body .ui-datatable.ui-datatable-sticky.ui-sticky {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-datatable.ui-datatable-sticky>table>thead,
body .ui-datatable.ui-datatable-sticky>table>tfoot {
    background: #f8f9fa
}

body .ui-columntoggler {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
    background: #fff;
    border: 1px solid #ced4da
}

body .ui-columntoggler .ui-columntoggler-close {
    display: none
}

body .ui-columntoggler .ui-columntoggler-items {
    padding: .5rem 0
}

body .ui-columntoggler .ui-columntoggler-items .ui-columntoggler-item {
    padding: .5rem 1rem;
    margin: 0;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    color: #495057;
    border-radius: 0
}

body .ui-columntoggler .ui-columntoggler-items .ui-columntoggler-item .ui-chkbox {
    position: static;
    margin-right: .5rem;
    margin-top: 0;
    vertical-align: middle
}

body .ui-columntoggler .ui-columntoggler-items .ui-columntoggler-item label {
    padding: 0;
    display: inline
}

@media(max-width: 640px) {
    body .ui-datatable-reflow .ui-datatable-data tr {
        border-bottom: 1px solid rgba(0, 0, 0, .08)
    }

    body .ui-datatable-reflow .ui-datatable-data tr:hover,
    body .ui-datatable-reflow .ui-datatable-data tr.ui-state-highlight {
        border-left: 0 none;
        border-right: 0 none;
        border-bottom: 1px solid rgba(0, 0, 0, .08)
    }

    body .ui-datatable-reflow .ui-datatable-data tr.ui-expanded-row-content>td {
        display: block;
        width: 100%
    }
}

body .ui-dataview .ui-paginator.ui-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0
}

body .ui-dataview .ui-paginator.ui-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0
}

body .ui-dataview .ui-dataview-header {
    background: #f8f9fa;
    color: #495057;
    padding: 1rem 1rem;
    font-weight: 600;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0
}

body .ui-dataview .ui-dataview-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-dataview .ui-dataview-content {
    border: 0 none;
    background: #fff;
    color: #495057;
    padding: 0
}

body .ui-dataview.ui-dataview-list .ui-dataview-row {
    border: solid #e9ecef;
    border-width: 0 0 1px 0
}

body .fc .fc-scrollgrid {
    border-color: #d8dee4
}

body .fc .fc-daygrid-day.fc-day-today,
body .fc .fc-timegrid-col.fc-day-today {
    background-color: #e6eef9
}

body .fc th {
    background: #f8f9fa;
    border: 1px solid #d8dee4;
    color: #495057
}

body .fc th>.fc-scrollgrid-sync-inner {
    padding: 1rem
}

body .fc td {
    border: 1px solid #d8dee4
}

body .fc td.fc-widget-content {
    background: #fff;
    border: 1px solid #d8dee4;
    color: #495057
}

body .fc td.fc-head-container {
    border: 1px solid #d8dee4
}

body .fc .fc-row {
    border-right: 1px solid #d8dee4
}

body .fc .fc-event {
    background: #0048b2;
    border: 0px solid #0048b2;
    color: #fff
}

body .fc .fc-divider {
    background: #f8f9fa;
    border: 1px solid #d8dee4
}

body .fc .fc-toolbar .fc-button {
    background: #288d2c;
    color: #fff;
    border: 1px solid #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .fc .fc-toolbar .fc-button:not(:disabled):hover {
    background: #0048b2;
    border-color: #0048b2;
    color: #fff
}

body .fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2em #82b5ff
}

body .fc .fc-toolbar .fc-button:active,
body .fc .fc-toolbar .fc-button.fc-button-active {
    background: #00409e;
    border-color: #00409e;
    color: #fff
}

body .ui-fluid .fc .fc-toolbar .ui-button {
    width: auto
}

body .ui-orderlist .ui-orderlist-controls {
    margin-right: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
}

body .ui-orderlist .ui-orderlist-controls .ui-button {
    margin-bottom: .5rem
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-n:before {
    content: ""
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-n:before {
    content: ""
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrow-1-s:before {
    content: ""
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-orderlist .ui-orderlist-controls .ui-icon-arrowstop-1-s:before {
    content: ""
}

body .ui-orderlist .ui-orderlist-caption {
    background: #f8f9fa;
    border: 1px solid #d8dee4;
    color: #495057;
    padding: 1rem;
    border-bottom: 0 none;
    font-weight: 700;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-orderlist .ui-g>div {
    padding: 0
}

body .ui-orderlist .ui-g>div.ui-orderlist-controls {
    padding: 0 .5rem 0 0
}

body .ui-orderlist .ui-orderlist-list {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: .5rem 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
    padding: .5rem 1rem;
    margin: 0;
    border: 0 none;
    color: #495057;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight.ui-sortable-placeholder {
    background: #99b9e6
}

body .ui-orderlist.ui-orderlist-controls-right .ui-g>div.ui-orderlist-controls {
    padding: 0 0 0 .5rem
}

@media(max-width: 640px) {
    body .ui-orderlist.ui-grid-responsive .ui-orderlist-controls {
        -ms-flex-direction: row;
        flex-direction: row
    }

    body .ui-orderlist.ui-grid-responsive .ui-orderlist-controls .ui-button {
        margin-top: .5rem;
        margin-right: .5rem
    }
}

body .ui-paginator {
    background: #fff;
    border: solid #e9ecef;
    border-width: 0;
    padding: 1rem
}

body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
    color: #288d2c;
    width: 2.286em;
    height: 2.286em;
    margin: 0 .125em;
    border: 1px solid rgba(0, 0, 0, 0);
    vertical-align: top;
    padding: 0;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-paginator .ui-paginator-first span,
body .ui-paginator .ui-paginator-prev span,
body .ui-paginator .ui-paginator-next span,
body .ui-paginator .ui-paginator-last span {
    display: none
}

body .ui-paginator .ui-paginator-first.ui-state-hover,
body .ui-paginator .ui-paginator-prev.ui-state-hover,
body .ui-paginator .ui-paginator-next.ui-state-hover,
body .ui-paginator .ui-paginator-last.ui-state-hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-paginator .ui-paginator-first.ui-state-focus,
body .ui-paginator .ui-paginator-prev.ui-state-focus,
body .ui-paginator .ui-paginator-next.ui-state-focus,
body .ui-paginator .ui-paginator-last.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-paginator .ui-paginator-first:before,
body .ui-paginator .ui-paginator-prev:before,
body .ui-paginator .ui-paginator-next:before,
body .ui-paginator .ui-paginator-last:before {
    position: relative;
    line-height: inherit;
    top: -1px
}

body .ui-paginator .ui-paginator-first {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-first:before {
    content: ""
}

body .ui-paginator .ui-paginator-first:before {
    position: relative
}

body .ui-paginator .ui-paginator-prev {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-prev:before {
    content: ""
}

body .ui-paginator .ui-paginator-prev:before {
    position: relative
}

body .ui-paginator .ui-paginator-next {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-next:before {
    content: ""
}

body .ui-paginator .ui-paginator-next:before {
    position: relative
}

body .ui-paginator .ui-paginator-last {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    line-height: 2.286em
}

body .ui-paginator .ui-paginator-last:before {
    content: ""
}

body .ui-paginator .ui-paginator-last:before {
    position: relative
}

body .ui-paginator .ui-paginator-current {
    color: #288d2c
}

body .ui-paginator .ui-paginator-pages {
    padding: 0;
    display: inline-block
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page {
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    text-align: center;
    line-height: 2.286em;
    min-width: 2.286em;
    height: 2.286em;
    color: #288d2c;
    margin: 0 .125em;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background: #e6eef9;
    color: #495057
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-paginator .ui-paginator-current,
body .ui-paginator .ui-paginator-rpp-options {
    margin: .5rem;
    display: inline-block;
    vertical-align: middle
}

body .ui-picklist .ui-picklist-buttons {
    padding: 0 .5rem 0 .5rem
}

body .ui-picklist .ui-picklist-buttons .ui-button {
    margin-bottom: .5rem
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-n:before {
    content: ""
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-n:before {
    content: ""
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-s:before {
    content: ""
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-s:before {
    content: ""
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-e:before {
    content: ""
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-e:before {
    content: ""
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-w {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrow-1-w:before {
    content: ""
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-w {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-picklist .ui-picklist-buttons .ui-icon-arrowstop-1-w:before {
    content: ""
}

body .ui-picklist .ui-picklist-caption {
    background: #f8f9fa;
    border: 1px solid #d8dee4;
    color: #495057;
    padding: 1rem;
    border-bottom: 0 none;
    font-weight: 700;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter {
    padding: .5rem .5rem;
    width: 100%;
    padding-left: 2rem;
    margin-bottom: .5rem
}

body .ui-picklist .ui-picklist-filter-container .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: absolute;
    left: .5rem;
    top: 50%;
    right: auto;
    margin-top: -0.75rem;
    color: #288d2c
}

body .ui-picklist .ui-picklist-filter-container .ui-icon:before {
    content: ""
}

body .ui-picklist .ui-picklist-list {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: .5rem 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-picklist .ui-picklist-list .ui-picklist-item {
    padding: .5rem 1rem;
    border: 0 none;
    margin: 0;
    color: #495057;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight.ui-sortable-placeholder {
    background: #99b9e6
}

@media(max-width: 640px) {
    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button {
        margin-top: .5rem;
        margin-right: .5rem
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button:last-child {
        margin-right: 0
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-e {
        font-family: "primeicons" !important;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        background: none
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-e:before {
        content: ""
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-e {
        font-family: "primeicons" !important;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        background: none
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-e:before {
        content: ""
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-w {
        font-family: "primeicons" !important;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        background: none
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrow-1-w:before {
        content: ""
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-w {
        font-family: "primeicons" !important;
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        background: none
    }

    body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons .ui-button .ui-icon-arrowstop-1-w:before {
        content: ""
    }

    body .ui-picklist.ui-picklist-responsive>div.ui-helper-hidden-accessible {
        display: none
    }
}

body .ui-tagcloud {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-tagcloud a {
    color: #495057;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-tagcloud a.ui-state-hover {
    background: #e6eef9;
    color: #495057
}

body .timeline-frame {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057
}

body .timeline-frame .timeline-navigation {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .timeline-frame .timeline-navigation:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .timeline-frame .timeline-navigation:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomin {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomin:before {
    content: ""
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomout {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-zoomout:before {
    content: ""
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-w {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-w:before {
    content: ""
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .timeline-frame .timeline-navigation .ui-icon.ui-icon-circle-arrow-e:before {
    content: ""
}

body .timeline-frame .timeline-event {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057
}

body .timeline-frame .timeline-event.ui-state-active {
    background: #e6eef9;
    color: #495057
}

body .vis-timeline {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057
}

body .vis-timeline .vis-item {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057
}

body .vis-timeline .vis-item .vis-item-content {
    color: #495057
}

body .vis-timeline .vis-item.vis-dot {
    border-width: 4px
}

body .vis-timeline .vis-item.vis-selected {
    background: #e6eef9;
    border-color: #e6eef9
}

body .vis-timeline .vis-item.vis-selected .vis-item-content {
    color: #495057
}

body .vis-timeline .vis-time-axis .vis-text {
    color: #495057
}

body .vis-timeline .vis-panel .vis-shadow {
    box-shadow: none
}

body .ui-tree {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem;
    width: 100%;
    border-radius: 4px
}

body .ui-tree .ui-tree-container {
    padding: 0;
    margin: 0
}

body .ui-tree .ui-tree-container .ui-treenode {
    padding: .143em 0
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
    display: inline-block;
    float: none;
    margin: 0 .5rem 0 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-e:before {
    content: ""
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-w {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler.ui-icon-triangle-1-w:before {
    content: ""
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-leaf-icon {
    width: 2rem;
    height: 2rem;
    margin: 0 .5rem 0 0
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
    margin: 0 .5rem 0 0
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    margin: 0;
    padding: .286em;
    border-radius: 4px
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
    margin-right: 1rem
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon-minus {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon-minus:before {
    content: ""
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-state-highlight .ui-tree-toggler,
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-state-highlight .ui-treenode-icon {
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-state-highlight .ui-tree-toggler:hover,
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-state-highlight .ui-treenode-icon:hover {
    color: #495057
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-tree-selectable:not(.ui-state-highlight):hover,
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-tree-selectable:not(.ui-state-highlight).ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-tree .ui-tree-container .ui-tree-droppoint.ui-state-hover {
    background: #e6eef9
}

body .ui-tree .ui-tree-container .ui-treenode-outline {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #b3cbee
}

body .ui-tree.ui-tree-horizontal {
    padding-left: 0;
    padding-right: 0
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem;
    border-radius: 4px
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler {
    margin-right: .5rem;
    vertical-align: middle
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-minus {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-minus:before {
    content: ""
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-plus {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-tree-toggler.ui-icon-plus:before {
    content: ""
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-treenode-icon {
    vertical-align: middle;
    margin-right: .5rem
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-treenode-label {
    padding: 0;
    vertical-align: middle
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-chkbox {
    margin-right: .5rem
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-chkbox .ui-icon-minus {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    color: #495057
}

body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-chkbox .ui-icon-minus:before {
    content: ""
}

body .ui-tree.ui-tree-horizontal .ui-treenode:before {
    background-color: #fff
}

body .ui-tree .ui-tree-filter-container {
    margin: 0 0 .5rem 0
}

body .ui-tree .ui-tree-filter-container .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: absolute;
    left: .5rem;
    top: 50%;
    right: auto;
    bottom: auto;
    margin-top: -0.5rem;
    color: #288d2c
}

body .ui-tree .ui-tree-filter-container .ui-icon:before {
    content: ""
}

body .ui-tree .ui-tree-filter-container .ui-inputfield {
    padding: .5rem .5rem;
    width: 100%;
    padding-left: 2rem
}

body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
    margin-right: 0;
    margin-left: .5rem
}

body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-leaf-icon {
    margin: 0 0 0 .5rem
}

body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
    margin: 0 0 0 .5rem
}

body .ui-tree.ui-tree-rtl .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
    margin-right: 0;
    margin-left: 2.5rem
}

body .ui-tree-draghelper {
    border: 1px solid #e6eef9
}

body .ui-fluid .ui-tree {
    width: 100%
}

body .ui-treetable .ui-treetable-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

body .ui-treetable .ui-treetable-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

body .ui-treetable .ui-treetable-header {
    text-align: left
}

body .ui-treetable .ui-treetable-footer {
    text-align: left
}

body .ui-treetable .ui-paginator {
    padding: 1rem
}

body .ui-treetable .ui-paginator.ui-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0
}

body .ui-treetable .ui-paginator.ui-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0
}

body .ui-treetable thead th {
    background: #f8f9fa;
    color: #495057;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    text-align: left;
    box-sizing: content-box;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-treetable thead th .ui-column-title {
    font-weight: 700;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon {
    margin: 0 0 0 .5rem
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-carat-2-n-s:before {
    content: ""
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-n:before {
    content: ""
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    vertical-align: middle
}

body .ui-treetable thead th .ui-sortable-column-icon.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-treetable thead th .ui-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #fff;
    background: #288d2c;
    margin-left: .5rem;
    font-size: .75rem
}

body .ui-treetable thead th.ui-sortable-column.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .2rem #b3cbee
}

body .ui-treetable thead th.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-treetable thead th.ui-state-active {
    background: #f8f9fa;
    color: #288d2c
}

body .ui-treetable thead th.ui-state-active.ui-state-hover {
    background: #e9ecef;
    color: #288d2c
}

body .ui-treetable .ui-treetable-data>tr {
    background: #fff;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-treetable .ui-treetable-data>tr>td {
    padding: 1rem 1rem;
    border: 1px solid rgba(0, 0, 0, .08);
    border-width: 0 0 1px 0
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler {
    margin-right: .5rem;
    margin-top: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-e:before {
    content: ""
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-treetable .ui-treetable-data>tr>td .ui-treetable-toggler.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-treetable .ui-treetable-data>tr>td .ui-chkbox.ui-selection {
    vertical-align: middle;
    margin-right: 1rem
}

body .ui-treetable .ui-treetable-data>tr>td .ui-chkbox.ui-selection .ui-icon-minus {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr>td .ui-chkbox.ui-selection .ui-icon-minus:before {
    content: ""
}

body .ui-treetable .ui-treetable-data>tr.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr.ui-state-highlight {
    background: #e6eef9;
    color: #495057;
    cursor: default
}

body .ui-treetable .ui-treetable-data>tr.ui-state-highlight .ui-treetable-toggler {
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr.ui-state-highlight .ui-treetable-toggler:hover {
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-pencil:before {
    content: ""
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-check {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin-right: .5rem
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-check:before {
    content: ""
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-close {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-icon.ui-icon-close:before {
    content: ""
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-row-editor-check {
    display: none
}

body .ui-treetable .ui-treetable-data>tr .ui-row-editor .ui-row-editor-close {
    display: none
}

body .ui-treetable .ui-treetable-data>tr.ui-row-editing .ui-row-editor-check {
    display: inline-block
}

body .ui-treetable .ui-treetable-data>tr.ui-row-editing .ui-row-editor-close {
    display: inline-block;
    color: #495057
}

body .ui-treetable .ui-treetable-data>tr.ui-row-editing .ui-icon {
    color: #495057
}

body .ui-treetable tfoot td {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    color: #495057;
    padding: 1rem 1rem;
    font-weight: 700;
    box-sizing: content-box
}

body .ui-treetable .ui-treetable-scrollable-header,
body .ui-treetable .ui-treetable-scrollable-footer {
    background: #f8f9fa
}

body .ui-treetable .ui-column-resizer-helper {
    background: #288d2c
}

body .ui-treetable.ui-treetable-sm thead>tr>th {
    padding: .5rem .5rem
}

body .ui-treetable.ui-treetable-sm .ui-treetable-scrollable-theadclone>tr>th {
    padding-top: 0;
    padding-bottom: 0
}

body .ui-treetable.ui-treetable-sm .ui-treetable-data>tr>td {
    padding: .5rem .5rem
}

body .ui-treetable.ui-treetable-sm tfoot>tr>td {
    padding: .5rem .5rem
}

body .ui-treetable.ui-treetable-lg thead>tr>th {
    padding: 1.25rem 1.25rem
}

body .ui-treetable.ui-treetable-lg .ui-treetable-scrollable-theadclone>tr>th {
    padding-top: 0;
    padding-bottom: 0
}

body .ui-treetable.ui-treetable-lg .ui-treetable-data>tr>td {
    padding: 1.25rem 1.25rem
}

body .ui-treetable.ui-treetable-lg tfoot>tr>td {
    padding: 1.25rem 1.25rem
}

body .ui-treetable.ui-treetable-gridlines .ui-treetable-header {
    border-width: 1px 1px 0
}

body .ui-treetable.ui-treetable-gridlines thead>tr>th {
    border: 1px solid #e9ecef
}

body .ui-treetable.ui-treetable-gridlines .ui-treetable-data>tr>td {
    border-width: 1px
}

body .ui-treetable.ui-treetable-gridlines .ui-treetable-scrollable-theadclone>tr>th {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0 none;
    border-top: 0 none
}

body .ui-treetable.ui-treetable-gridlines tfoot>tr>td {
    border-width: 1px
}

body .ui-fileupload .ui-fileupload-buttonbar {
    border: 1px solid #d8dee4;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
    margin-right: .5rem
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-state-disabled:not(.ui-fileupload-choose) {
    display: none
}

body .ui-fileupload .ui-fileupload-content {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem;
    border-top: 0 none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-fileupload .ui-fileupload-content .ui-fileupload-cancel .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-fileupload .ui-fileupload-content .ui-fileupload-cancel .ui-icon:before {
    content: ""
}

body .ui-fileupload .ui-fileupload-content .ui-fileupload-files .ui-fileupload-row>div {
    padding: 1rem
}

body .ui-fileupload-simple .ui-icon-plusthick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-fileupload-simple .ui-icon-plusthick:before {
    content: ""
}

@media screen and (max-width: 40em) {

    body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-choose .ui-icon,
    body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-upload .ui-icon,
    body .ui-fileupload .ui-fileupload-buttonbar .ui-button.ui-fileupload-cancel .ui-icon {
        left: 50%;
        margin-left: -0.5rem
    }
}

body .ui-autocomplete .ui-autocomplete-input.ui-autocomplete-dd-input {
    padding: .5rem .5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

body .ui-autocomplete.ui-state-loading .ui-icon-loading {
    right: .5rem
}

body .ui-autocomplete.ui-state-loading.ui-autocomplete-dd .ui-icon-loading {
    right: 2.857rem
}

body .ui-autocomplete .ui-autocomplete-dropdown {
    position: relative;
    height: auto;
    width: 2.357rem;
    right: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

body .ui-autocomplete .ui-autocomplete-dropdown .ui-button-text {
    padding: .5rem
}

body .ui-autocomplete .ui-autocomplete-dropdown .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-autocomplete .ui-autocomplete-dropdown .ui-icon:before {
    content: ""
}

body .ui-autocomplete .ui-autocomplete-multiple-container {
    padding: .25rem .5rem
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token {
    padding: 1px 0;
    margin: 0 .5rem 0 0;
    background: #e6eef9;
    color: #495057;
    display: inline-block;
    vertical-align: middle;
    float: none;
    border-radius: 4px
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token .ui-autocomplete-token-label {
    padding: 0 2rem 0 .5rem
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token .ui-autocomplete-token-icon {
    margin-top: -0.5rem;
    position: absolute;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-token .ui-autocomplete-token-icon:before {
    content: ""
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
    margin: 0;
    padding: .25rem 0;
    display: inline-block;
    vertical-align: middle;
    float: none
}

body .ui-autocomplete .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    color: #495057;
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    margin: 0
}

body .ui-autocomplete .ui-autocomplete-multiple-container.ui-autocomplete-dd-multiple-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

body .ui-autocomplete-panel {
    padding: 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-autocomplete-panel .ui-autocomplete-emptyMessage,
body .ui-autocomplete-panel .ui-autocomplete-empty-message {
    border: 0 none;
    margin: 0;
    padding: .5rem 1rem;
    color: #495057;
    border-radius: 0
}

body .ui-autocomplete-panel .ui-autocomplete-items {
    padding: .5rem 0;
    line-height: normal;
    border: 0 none
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item {
    border: 0 none;
    margin: 0;
    padding: .5rem 1rem;
    color: #495057;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item.ui-autocomplete-row {
    background: #fff;
    color: #495057;
    border: 1px solid rgba(0, 0, 0, .08)
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item.ui-autocomplete-row>td {
    padding: 1rem 1rem
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-item.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-autocomplete-itemtip {
    padding: 0
}

body .ui-autocomplete-itemtip.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-fluid .ui-autocomplete .ui-autocomplete-input.ui-autocomplete-dd-input {
    width: calc(100% - 2.357rem)
}

body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
    width: 2.357rem
}

body .ui-fluid .ui-autocomplete-multiple-container.ui-autocomplete-dd-multiple-container {
    width: calc(100% - 2.357rem)
}

body .ui-cascadeselect {
    background: #fafdff;
    border: 1px solid #ced4da;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 4px
}

body .ui-cascadeselect:not(.ui-state-disabled):hover {
    border-color: #288d2c
}

body .ui-cascadeselect:not(.ui-state-disabled).ui-state-focus {
    border-color: #065dea;
    outline: 0 none;
    box-shadow: 0 0 0 .2em #82b5ff
}

body .ui-cascadeselect .ui-cascadeselect-label {
    background: rgba(0, 0, 0, 0);
    border: 0 none;
    padding: .5rem .5rem
}

body .ui-cascadeselect .ui-cascadeselect-label.ui-placeholder {
    color: #288d2c
}

body .ui-cascadeselect .ui-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none
}

body .ui-cascadeselect .ui-cascadeselect-trigger {
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-cascadeselect-panel {
    background: #fff;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-cascadeselect-panel .ui-cascadeselect-items {
    padding: .5rem 0
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #495057;
    background: rgba(0, 0, 0, 0);
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 0
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item .ui-cascadeselect-item-content {
    padding: .5rem 1rem
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item .ui-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #b3cbee
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item.ui-state-highlight {
    color: #495057;
    background: #e6eef9
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
    color: #495057;
    background: #e9ecef
}

body .ui-cascadeselect-panel .ui-cascadeselect-items .ui-cascadeselect-item .ui-cascadeselect-group-icon {
    font-size: 1rem
}

body .ui-input-filled .ui-cascadeselect {
    background: #fafdff
}

body .ui-input-filled .ui-cascadeselect:not(.ui-state-disabled):hover {
    background-color: #f8f9fa
}

body .ui-input-filled .ui-cascadeselect:not(.ui-state-disabled).p-focus {
    background-color: #f8f9fa
}

body .ui-chkbox {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    margin: 0;
    width: auto;
    height: auto
}

body .ui-chkbox .ui-chkbox-box {
    border: 1px solid #ced4da;
    border-width: 2px;
    background: #fafdff;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    position: relative;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-chkbox .ui-chkbox-box.ui-state-hover {
    border-color: #288d2c
}

body .ui-chkbox .ui-chkbox-box.ui-state-focus {
    box-shadow: 0 0 0 .2em #82b5ff;
    outline: 0 none
}

body .ui-chkbox .ui-chkbox-box.ui-state-active {
    border-color: #288d2c;
    background: #288d2c;
    color: #fff
}

body .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-hover {
    background: #0048b2
}

body .ui-chkbox .ui-chkbox-box.ui-state-error {
    border-color: #f44336
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    overflow: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -0.5rem;
    margin-top: -0.5rem
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-check {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-check:before {
    content: ""
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-closethick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon.ui-icon-closethick:before {
    content: ""
}

body.ui-input-filled .ui-chkbox .ui-chkbox-box,
body .ui-input-filled .ui-chkbox .ui-chkbox-box {
    background-color: #fafdff
}

body.ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-hover,
body .ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-hover {
    background-color: #f8f9fa
}

body.ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active,
body .ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active {
    background: #288d2c
}

body.ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-hover,
body .ui-input-filled .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-hover {
    background: #288d2c
}

body .ui-chips .ui-chips-container {
    padding: .25rem .5rem
}

body .ui-chips .ui-chips-container .ui-chips-token {
    padding: 1px 0;
    margin: .125rem;
    background: #e6eef9;
    color: #495057;
    border-radius: 4px
}

body .ui-chips .ui-chips-container .ui-chips-token .ui-chips-token-label {
    padding: 0 2rem 0 .5rem
}

body .ui-chips .ui-chips-container .ui-chips-token .ui-chips-token-icon {
    margin-top: -0.5rem;
    position: absolute;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-chips .ui-chips-container .ui-chips-token .ui-chips-token-icon:before {
    content: ""
}

body .ui-chips .ui-chips-container .ui-chips-input-token {
    margin: 0;
    padding: .25rem 0
}

body .ui-chips .ui-chips-container .ui-chips-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    color: #495057;
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    margin: 0;
    border-radius: 0
}

body .ui-colorpicker .ui-button {
    border-radius: 4px
}

body .ui-fluid .ui-colorpicker .ui-button {
    width: auto;
    min-width: auto
}

body .ui-datepicker {
    background: #fff;
    border: 1px solid #ced4da;
    color: #495057;
    padding: .5rem;
    width: auto;
    border-radius: 4px
}

body .ui-datepicker .ui-datepicker-header {
    margin: 0;
    padding: 1rem;
    line-height: normal;
    background: #fff;
    color: #495057;
    font-weight: 600;
    border: solid #d8dee4;
    border-width: 0 0 1px 0;
    border-radius: 0
}

body .ui-datepicker .ui-datepicker-next {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    right: 0
}

body .ui-datepicker .ui-datepicker-next:before {
    content: ""
}

body .ui-datepicker .ui-datepicker-prev {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    left: 0
}

body .ui-datepicker .ui-datepicker-prev:before {
    content: ""
}

body .ui-datepicker .ui-datepicker-next,
body .ui-datepicker .ui-datepicker-prev {
    cursor: pointer;
    top: 50%;
    margin-top: -1rem;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-datepicker .ui-datepicker-next:hover,
body .ui-datepicker .ui-datepicker-prev:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datepicker .ui-datepicker-next:focus,
body .ui-datepicker .ui-datepicker-prev:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datepicker .ui-datepicker-next span,
body .ui-datepicker .ui-datepicker-prev span {
    display: none
}

body .ui-datepicker .ui-datepicker-title {
    line-height: 1;
    margin: 0
}

body .ui-datepicker .ui-datepicker-title select {
    width: 40%;
    margin: 0
}

body .ui-datepicker .ui-datepicker-title select:first-child {
    margin-right: .5rem
}

body .ui-datepicker .ui-datepicker-calendar {
    margin: .5rem 0
}

body .ui-datepicker .ui-datepicker-calendar th {
    padding: .5rem;
    text-align: center
}

body .ui-datepicker .ui-datepicker-calendar th>span {
    width: 2.5rem;
    height: 2.5rem
}

body .ui-datepicker .ui-datepicker-calendar td {
    padding: .5rem;
    text-align: center
}

body .ui-datepicker .ui-datepicker-calendar td>a:hover {
    background: #e9ecef;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datepicker .ui-datepicker-calendar td>a,
body .ui-datepicker .ui-datepicker-calendar td>span {
    padding: 0;
    color: #495057;
    text-align: center;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border: 0 none;
    display: inline-block;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%
}

body .ui-datepicker .ui-datepicker-calendar td>a:focus,
body .ui-datepicker .ui-datepicker-calendar td>span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datepicker .ui-datepicker-calendar td>a.ui-state-active,
body .ui-datepicker .ui-datepicker-calendar td>span.ui-state-active {
    background: #e6eef9;
    color: #495057;
    border: 0 none
}

body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today>a:hover,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day>a:hover {
    background: #e9ecef;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today>a,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today>span,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day>a,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day>span {
    background: rgba(0, 0, 0, 0);
    color: #00409e;
    font-weight: 600;
    border: 0 none
}

body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today>a.ui-state-active,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today>span.ui-state-active,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day>a.ui-state-active,
body .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day>span.ui-state-active {
    background: #e6eef9;
    color: #495057;
    border: 0 none
}

body .ui-datepicker.ui-input-overlay {
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-datepicker .ui-timepicker-div dl {
    margin: .857em 0
}

body .ui-datepicker .ui-timepicker-div dl dt {
    padding: 0
}

body .ui-datepicker .ui-timepicker-div dl dt.ui_tpicker_time_label {
    padding: .5em 0
}

body .ui-datepicker .ui-timepicker-div dl dd {
    padding: .5em 0;
    margin: 0 .714em .714em 40%
}

body .ui-datepicker .ui-timepicker-timeinput input {
    color: #495057;
    background: #fafdff;
    padding: .5rem .5rem;
    border: 1px solid #ced4da;
    outline: 0 none;
    font-size: 1rem;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    -webkit-appearance: none;
    appearance: none;
    width: auto
}

body .ui-datepicker .ui-timepicker-timeinput input:hover {
    border-color: #288d2c
}

body .ui-datepicker .ui-timepicker-timeinput input:focus {
    border-color: #288d2c;
    outline: 0 none;
    box-shadow: 0 0 0 .2em #20433B
}

body .ui-datepicker .ui-timepicker-timeinput .ui-separator>span {
    padding: .5rem .5rem;
    font-size: 1rem
}

body .ui-datepicker .ui-datepicker-buttonpane button,
body .ui-datepicker .ui-datepicker-buttonbar button {
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 4px;
    background: #6d35d0;
    color: #fff;
    border: 1px solid #6d35d0
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-state-hover,
body .ui-datepicker .ui-datepicker-buttonpane button:not(:disabled):hover,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-state-hover,
body .ui-datepicker .ui-datepicker-buttonbar button:not(:disabled):hover {
    background: #612cbf;
    border-color: #612cbf;
    color: #fff
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-state-focus,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-state-focus {
    box-shadow: 0 0 0 .2rem #c5aeec
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-state-active,
body .ui-datepicker .ui-datepicker-buttonpane button:active,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-state-active,
body .ui-datepicker .ui-datepicker-buttonbar button:active {
    background: #5627a9;
    border-color: #5627a9;
    color: #fff
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-outlined,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #6d35d0;
    border: 1px solid
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-outlined:enabled:hover,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-outlined:enabled:hover {
    background: rgba(109, 53, 208, .04);
    color: #6d35d0;
    border: 1px solid
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-outlined:enabled:active,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-outlined:enabled:active {
    background: rgba(109, 53, 208, .16);
    color: #6d35d0;
    border: 1px solid
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-flat,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-flat {
    background-color: rgba(0, 0, 0, 0);
    color: #6d35d0;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-flat:enabled:hover,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-flat:enabled:hover {
    background: rgba(109, 53, 208, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #6d35d0
}

body .ui-datepicker .ui-datepicker-buttonpane button.ui-button-flat:enabled:active,
body .ui-datepicker .ui-datepicker-buttonbar button.ui-button-flat:enabled:active {
    background: rgba(109, 53, 208, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #6d35d0
}

body .ui-datepicker .ui-datepicker-buttonpane .ui-g-6:first-child,
body .ui-datepicker .ui-datepicker-buttonbar .ui-g-6:first-child {
    text-align: left
}

body .ui-datepicker .ui-datepicker-buttonpane .ui-g-6:last-child,
body .ui-datepicker .ui-datepicker-buttonbar .ui-g-6:last-child {
    text-align: right
}

body .ui-datepicker .ui-datepicker-buttonbar {
    border-top: solid #d8dee4;
    padding: 1rem 0
}

body .ui-datepicker .ui-monthpicker-month {
    margin: .5rem 0;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    padding: .5rem;
    border: 4px
}

body .ui-datepicker .ui-monthpicker-month:hover {
    background: #e9ecef;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datepicker .ui-monthpicker-month:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datepicker .ui-monthpicker-month.ui-state-active {
    background: #e6eef9;
    color: #495057;
    border: 0 none
}

body .ui-datepicker .ui-datepicker-group {
    padding: .5rem
}

body .ui-datepicker .ui-picker-up {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-datepicker .ui-picker-up:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datepicker .ui-picker-up:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datepicker .ui-picker-up .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datepicker .ui-picker-up .ui-icon:before {
    content: ""
}

body .ui-datepicker .ui-picker-down {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-datepicker .ui-picker-down:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-datepicker .ui-picker-down:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-datepicker .ui-picker-down .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-datepicker .ui-picker-down .ui-icon:before {
    content: ""
}

body .ui-datepicker.ui-datepicker-multi {
    width: auto !important
}

body .ui-calendar .ui-button.ui-button-icon-only {
    width: 2.357rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

body .ui-calendar .ui-button.ui-button-icon-only .ui-icon-calendar {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-calendar .ui-button.ui-button-icon-only .ui-icon-calendar:before {
    content: ""
}

body .ui-calendar.ui-trigger-calendar input.hasDatepicker {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

body .ui-fluid .ui-trigger-calendar input.hasDatepicker {
    width: calc(100% - 2.357rem);
    padding-right: 0
}

body .ui-fluid .ui-trigger-calendar .ui-datepicker-trigger.ui-button {
    position: absolute;
    right: -2.357rem;
    width: 2.357rem
}

body .ui-fluid .ui-trigger-calendar .ui-datepicker-trigger.ui-button .ui-button-text {
    padding: .5rem
}

body .ui-fluid .p-datepicker .ui-datepicker-trigger.ui-button {
    right: 0
}

body .ui-inplace .ui-inplace-display {
    padding: .5rem .5rem;
    border-radius: 4px
}

body .ui-inplace .ui-inplace-display.ui-state-highlight {
    background: #e9ecef;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-inplace .ui-inplace-display.ui-state-focus {
    border-color: #065dea;
    outline: 0 none;
    box-shadow: 0 0 0 .2em #82b5ff
}

body .ui-inplace .ui-inplace-save .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-inplace .ui-inplace-save .ui-icon:before {
    content: ""
}

body .ui-inplace .ui-inplace-cancel {
    margin-left: .25rem
}

body .ui-inplace .ui-inplace-cancel .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-inplace .ui-inplace-cancel .ui-icon:before {
    content: ""
}

body .ui-inplace .ui-inplace-editor {
    margin-left: .5rem
}

body .ui-inplace .ui-inplace-editor .ui-button-icon-only .ui-button-text {
    padding: .5rem
}

body ::-webkit-input-placeholder {
    color: #288d2c
}

body :-moz-placeholder {
    color: #288d2c;
    opacity: 1
}

body ::-moz-placeholder {
    color: #288d2c;
    opacity: 1
}

body :-ms-input-placeholder {
    color: #288d2c
}

body .p-field small.ui-state-error,
body .field small.ui-state-error {
    color: #f44336
}

body .ui-float-label>label {
    margin-left: .5rem;
    color: #288d2c
}

body .ui-float-label>input:focus~label,
body .ui-float-label>input.ui-state-filled~label,
body .ui-float-label>textarea:focus~label,
body .ui-float-label>textarea.ui-state-filled~label,
body .ui-float-label>.ui-inputwrapper-focus~label,
body .ui-float-label>.ui-inputwrapper-filled~label {
    color: #495057
}

body .ui-float-label>input:-webkit-autofill~label,
body .ui-float-label>textarea:-webkit-autofill~label {
    color: #495057
}

body .ui-input-icon-left,
body .ui-input-icon-right {
    position: relative;
    display: inline-block
}

body .ui-fluid .ui-input-icon-left,
body .ui-fluid .ui-input-icon-right {
    display: block
}

body .ui-input-icon-left>i,
body .ui-input-icon-right>i {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem
}

body .ui-input-icon-left>i:first-of-type {
    left: .5rem;
    color: #288d2c
}

body .ui-input-icon-left>.ui-inputfield {
    padding-left: 2rem
}

body .ui-input-icon-right>i:last-of-type {
    right: .5rem;
    color: #288d2c
}

body .ui-input-icon-right>.ui-inputfield {
    padding-right: 2rem
}

body .ui-inputfield {
    color: #495057;
    background: #fafdff;
    padding: .5rem .5rem;
    border: 1px solid #ced4da;
    outline: 0 none;
    font-size: 1rem;
    border-radius: 4px;
    transition: background-color .2s, color 0, border-color .2s, box-shadow .2s, opacity 0;
    -webkit-appearance: none;
    appearance: none
}

body .ui-inputfield.ui-state-hover {
    border-color: #288d2c
}

body .ui-inputfield.ui-state-focus {
    border-color: #288d2c;
    outline: 0 none;
    box-shadow: 0 0 0 .2em #20433B
}

body .ui-inputfield.ui-state-error {
    border-color: #f44336
}

body .ui-input-filled .ui-inputfield,
body.ui-input-filled .ui-inputfield {
    background-color: #fafdff
}

body .ui-input-filled .ui-inputfield.ui-state-hover,
body.ui-input-filled .ui-inputfield.ui-state-hover {
    background-color: #f8f9fa
}

body .ui-input-filled .ui-inputfield.ui-state-focus,
body.ui-input-filled .ui-inputfield.ui-state-focus {
    background-color: #f8f9fa
}

body .ui-inputtextarea {
    vertical-align: top
}

body .ui-inputgroup .ui-inputgroup-addon,
body .ui-inputgroup .ui-inputgroup-addon-checkbox {
    padding: .5rem .5rem;
    border-color: #ced4da;
    background: #e9ecef;
    color: #288d2c;
    min-width: 2.357rem
}

body .ui-inputgroup .ui-inputgroup-addon:first-child,
body .ui-inputgroup .ui-inputgroup-addon-checkbox:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-inputgroup .ui-inputgroup-addon:last-child,
body .ui-inputgroup .ui-inputgroup-addon-checkbox:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-inputgroup button:first-child,
body .ui-inputgroup input:first-child,
body .ui-inputgroup textarea:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-inputgroup .ui-float-label:first-child input,
body .ui-inputgroup .ui-float-label:first-child textarea {
    border-right-width: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-inputgroup button:last-child,
body .ui-inputgroup input:last-child,
body .ui-inputgroup textarea:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-inputgroup .ui-float-label:last-child input,
body .ui-inputgroup .ui-float-label:last-child textarea {
    border-left-width: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-inputgroup>.ui-widget,
body .ui-inputgroup>.ui-float-label>.ui-widget {
    border-radius: 0
}

body .ui-inputgroup>.ui-widget.ui-state-focus,
body .ui-inputgroup>.ui-float-label>.ui-widget.ui-state-focus {
    z-index: 1
}

body .ui-inputgroup>.ui-widget.ui-state-focus~label,
body .ui-inputgroup>.ui-float-label>.ui-widget.ui-state-focus~label {
    z-index: 1
}

body .ui-inputgroup .ui-inputgroup-addon-checkbox {
    padding: 0;
    position: relative
}

body .ui-inputgroup .ui-inputgroup-addon-checkbox .ui-chkbox {
    vertical-align: baseline;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.75rem;
    margin-left: -0.75rem
}

body .ui-inputswitch {
    height: 14px;
    width: 34px !important;
    overflow: visible;
    background: #d8dee4;
    border-radius: 8px
}

body .ui-inputswitch .ui-inputswitch-handle {
    top: -4px;
    left: -2px;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #d8dee4;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    width: 20px !important;
    height: 20px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)
}

body .ui-inputswitch .ui-inputswitch-handle.ui-state-focus {
    border-color: #288d2c;
    outline: 0 none;
    box-shadow: 0 0 0 .2em #20433B
}

body .ui-inputswitch .ui-inputswitch-on {
    visibility: hidden
}

body .ui-inputswitch .ui-inputswitch-off span,
body .ui-inputswitch .ui-inputswitch-on span {
    visibility: hidden
}

body .ui-inputswitch.ui-inputswitch-checked {
    background: #288d2c
}

body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-handle {
    background: #fff;
    border-color: #288d2c;
    margin-left: 2px
}

body .keypad-popup {
    background: #fff;
    color: #495057;
    padding: 0;
    border: 0 none;
    margin: 0;
    width: auto;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .keypad-popup button {
    background: #fafdff;
    border: 1px solid #ced4da;
    padding: .5rem;
    margin: 2px;
    outline: 0 none;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 4px
}

body .keypad-popup button.ui-state-hover {
    background: #ebebef;
    border-color: #ced4da;
    color: #495057
}

body .keypad-popup button.ui-state-active {
    background: #00409e;
    border-color: #00409e;
    color: #fff
}

body .keypad-popup button.keypad-shift,
body .keypad-popup button.keypad-spacebar,
body .keypad-popup button.keypad-enter,
body .keypad-popup button.keypad-clear,
body .keypad-popup button.keypad-back,
body .keypad-popup button.keypad-close {
    background: #288d2c;
    color: #fff;
    border: 1px solid #288d2c
}

body .keypad-popup button.keypad-shift.ui-state-hover,
body .keypad-popup button.keypad-spacebar.ui-state-hover,
body .keypad-popup button.keypad-enter.ui-state-hover,
body .keypad-popup button.keypad-clear.ui-state-hover,
body .keypad-popup button.keypad-back.ui-state-hover,
body .keypad-popup button.keypad-close.ui-state-hover {
    background: #0048b2;
    border-color: #0048b2;
    color: #fff
}

body .keypad-popup button.keypad-shift.ui-state-focus,
body .keypad-popup button.keypad-spacebar.ui-state-focus,
body .keypad-popup button.keypad-enter.ui-state-focus,
body .keypad-popup button.keypad-clear.ui-state-focus,
body .keypad-popup button.keypad-back.ui-state-focus,
body .keypad-popup button.keypad-close.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2em #82b5ff
}

body .keypad-popup button.keypad-shift.ui-state-active,
body .keypad-popup button.keypad-spacebar.ui-state-active,
body .keypad-popup button.keypad-enter.ui-state-active,
body .keypad-popup button.keypad-clear.ui-state-active,
body .keypad-popup button.keypad-back.ui-state-active,
body .keypad-popup button.keypad-close.ui-state-active {
    background: #00409e;
    border-color: #00409e;
    color: #fff
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer {
    margin-right: .5rem;
    border: 1px solid #ced4da;
    padding: 0
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-header {
    padding: .5rem 1rem;
    margin: 0;
    border: 0 none;
    border-bottom: 0 none;
    background: #f8f9fa;
    color: #495057
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list {
    padding: .5rem 0;
    background: #fff;
    border: 0 none
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list .ui-multiselectlistbox-item {
    padding: .5rem 1rem;
    margin: 0;
    background: rgba(0, 0, 0, 0);
    color: #495057;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list .ui-multiselectlistbox-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-multiselectlistbox .ui-multiselectlistbox-listcontainer .ui-multiselectlistbox-list .ui-multiselectlistbox-item.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-password .ui-password-icon {
    cursor: pointer
}

body .ui-password.ui-password-masked .ui-password-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-password.ui-password-masked .ui-password-icon:before {
    content: ""
}

body .ui-password.ui-password-unmasked .ui-password-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-password.ui-password-unmasked .ui-password-icon:before {
    content: ""
}

body .ui-password-panel {
    background: #fff;
    color: #495057;
    padding: 1rem;
    border: 0 none;
    margin: 0;
    width: auto;
    min-width: 150px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 4px
}

body .ui-radiobutton {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px
}

body .ui-radiobutton .ui-radiobutton-box {
    border: 1px solid #ced4da;
    border-width: 2px;
    background: #fafdff;
    width: 20px;
    height: 20px;
    text-align: center;
    position: relative;
    border-radius: 50%;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-hover {
    border-color: #288d2c
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
    box-shadow: 0 0 0 .2em #288d2c
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
    border-color: #288d2c;
    background: #288d2c;
    color: #fff
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-hover {
    background: #288d2c
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-icon-bullet {
    background: #fff
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-error {
    border-color: #f44336
}

body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
    width: 12px;
    height: 12px;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    margin-left: -6px;
    border-radius: 50%
}

body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon:before {
    display: none
}

body.ui-input-filled .ui-radiobutton .ui-radiobutton-box,
body .ui-input-filled .ui-radiobutton .ui-radiobutton-box {
    background-color: #fafdff
}

body.ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-hover,
body .ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-hover {
    background-color: #f8f9fa
}

body.ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active,
body .ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active {
    background: #288d2c
}

body.ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-hover,
body .ui-input-filled .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-hover {
    background: #288d2c
}

body .ui-rating .ui-rating-cancel {
    float: none;
    display: inline-block;
    margin-right: .5rem;
    overflow: visible
}

body .ui-rating .ui-rating-cancel.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-rating .ui-rating-cancel a {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    text-indent: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    display: block;
    font-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
    color: #e0284f;
    overflow: visible
}

body .ui-rating .ui-rating-cancel a:before {
    content: ""
}

body .ui-rating .ui-rating-cancel a:hover {
    color: #e0284f
}

body .ui-rating .ui-rating-star {
    float: none;
    display: inline-block;
    margin-right: .5rem;
    overflow: visible
}

body .ui-rating .ui-rating-star.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-rating .ui-rating-star a {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    text-indent: 0;
    display: block;
    font-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
    color: #495057;
    overflow: visible
}

body .ui-rating .ui-rating-star a:before {
    content: ""
}

body .ui-rating .ui-rating-star a:hover {
    color: #0048b2
}

body .ui-rating .ui-rating-star-on a {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    display: block;
    font-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
    color: #288d2c
}

body .ui-rating .ui-rating-star-on a:before {
    content: ""
}

body .ui-state-highlight .ui-rating-star a {
    color: #495057
}

body .ui-selectbooleancheckbox,
body .ui-tristatecheckbox {
    width: auto;
    height: auto;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center
}

body .ui-selectcheckboxmenu {
    vertical-align: baseline;
    border: 1px solid #ced4da;
    position: relative;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 4px;
    background: #fafdff
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-label-container {
    padding: .5rem .5rem;
    padding-right: 2.357rem;
    display: block;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-label-container .ui-selectcheckboxmenu-label {
    padding: 0;
    background: rgba(0, 0, 0, 0)
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-trigger {
    border: 0 none;
    cursor: pointer;
    height: 100%;
    position: absolute;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 2.357rem;
    background: rgba(0, 0, 0, 0);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-trigger .ui-icon {
    position: static;
    margin: 0;
    color: #288d2c;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-trigger .ui-icon:before {
    content: ""
}

body .ui-selectcheckboxmenu.ui-state-hover {
    border-color: #288d2c
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #288d2c;
    background: #288d2c;
}


body .ui-selectcheckboxmenu.ui-state-focus {
    border-color: #288d2c;
    outline: 0 none;
    box-shadow: 0 0 0 .2em #20433B
}

body .ui-selectcheckboxmenu.ui-state-error {
    border-color: #f44336
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container.ui-inputfield {
    padding: .5rem .5rem;
    padding-right: 2.357rem
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token {
    padding: 1px 0;
    margin: 0 .5rem 0 0;
    background: #e6eef9;
    color: #495057;
    border-radius: 4px
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token .ui-selectcheckboxmenu-token-label {
    padding: 0 2rem 0 .5rem
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token .ui-selectcheckboxmenu-token-icon {
    margin-top: -0.5rem;
    position: absolute;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-token .ui-selectcheckboxmenu-token-icon:before {
    content: ""
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-input-token {
    margin: 0;
    padding: .25rem 0
}

body .ui-selectcheckboxmenu .ui-selectcheckboxmenu-multiple-container .ui-selectcheckboxmenu-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 0;
    margin: 0
}

body.ui-input-filled .ui-selectcheckboxmenu,
body .ui-input-filled .ui-selectcheckboxmenu {
    background: #fafdff
}

body.ui-input-filled .ui-selectcheckboxmenu.ui-state-hover,
body .ui-input-filled .ui-selectcheckboxmenu.ui-state-hover {
    background-color: #f8f9fa
}

body.ui-input-filled .ui-selectcheckboxmenu.ui-state-focus,
body .ui-input-filled .ui-selectcheckboxmenu.ui-state-focus {
    background-color: #f8f9fa
}

body .ui-selectcheckboxmenu-panel {
    padding: 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 4px
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header {
    padding: .5rem 1rem;
    margin: 0;
    border-bottom: 0 none;
    background: #f8f9fa;
    color: #495057;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-chkbox {
    margin-right: .5rem;
    float: none
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container {
    padding: 0;
    margin: 0 .5rem 0 0;
    float: none;
    flex: 1 1 0;
    -ms-flex: 1 1 0px
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container .ui-inputfield {
    padding: .5rem .5rem;
    width: 100%;
    padding-left: 2rem
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: absolute;
    left: .5rem;
    top: 50%;
    right: auto;
    bottom: auto;
    margin-top: -0.5rem;
    color: #288d2c
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-filter-container .ui-icon:before {
    content: ""
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close {
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none;
    margin-left: auto;
    text-align: center;
    margin-right: 0
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    float: none
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-header .ui-selectcheckboxmenu-close .ui-icon:before {
    content: ""
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items {
    padding: .5rem 0
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items.ui-selectcheckboxmenu-table>thead tr th {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    color: #495057;
    font-weight: 700
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item {
    padding: .5rem 1rem;
    margin: 0;
    outline: 0 none;
    background: rgba(0, 0, 0, 0);
    color: #495057;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item .ui-chkbox {
    top: auto;
    position: static;
    margin-top: 0;
    margin-right: .5rem
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item>label {
    display: inline;
    padding-left: 0;
    vertical-align: middle
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item.ui-selectcheckboxmenu-row {
    background: #fff;
    color: #495057;
    border: 1px solid rgba(0, 0, 0, .08)
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item.ui-selectcheckboxmenu-row>td {
    padding: 1rem 1rem
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item.ui-selectcheckboxmenu-row:first-child {
    border-top-color: rgba(0, 0, 0, 0)
}

body .ui-selectcheckboxmenu-panel .ui-selectcheckboxmenu-items .ui-selectcheckboxmenu-item-group {
    background: #fff;
    margin: 0;
    padding: .75rem 1rem;
    color: #495057
}

body .ui-selectonelistbox,
body .ui-selectmanymenu {
    padding: 0;
    width: auto;
    min-width: 12rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background: #fff
}

body .ui-selectonelistbox .ui-selectlistbox-filter-container,
body .ui-selectmanymenu .ui-selectlistbox-filter-container {
    padding: .5rem 1rem;
    margin: 0;
    border-bottom: 0 none;
    background: #f8f9fa;
    color: #495057;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-selectonelistbox .ui-selectlistbox-filter-container .ui-selectlistbox-filter.ui-inputfield,
body .ui-selectmanymenu .ui-selectlistbox-filter-container .ui-selectlistbox-filter.ui-inputfield {
    padding: .5rem .5rem;
    width: 100%;
    padding-left: 2rem
}

body .ui-selectonelistbox .ui-selectlistbox-filter-container .ui-icon,
body .ui-selectmanymenu .ui-selectlistbox-filter-container .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    right: auto;
    bottom: auto;
    margin-top: -0.5rem;
    color: #288d2c
}

body .ui-selectonelistbox .ui-selectlistbox-filter-container .ui-icon:before,
body .ui-selectmanymenu .ui-selectlistbox-filter-container .ui-icon:before {
    content: ""
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer {
    padding: .5rem 0;
    line-height: normal;
    border: 0 none
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list {
    list-style-type: none;
    margin: 0
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item {
    border: 0 none;
    margin: 0;
    padding: .5rem 1rem;
    background: rgba(0, 0, 0, 0);
    color: #495057;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-hover,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-highlight,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item .ui-chkbox,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item .ui-chkbox {
    margin-right: .5rem;
    vertical-align: middle
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item>td,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-selectlistbox-item>td {
    padding: 1rem 1rem
}

body .ui-selectonelistbox .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-listbox-outline,
body .ui-selectmanymenu .ui-selectlistbox-listcontainer .ui-selectlistbox-list .ui-listbox-outline {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #b3cbee
}

body .ui-input-filled .ui-selectonelistbox.ui-inputfield,
body .ui-input-filled .ui-selectmanymenu.ui-inputfield,
body.ui-input-filled .ui-selectonelistbox.ui-inputfield,
body.ui-input-filled .ui-selectmanymenu.ui-inputfield {
    background: #fff
}

body .ui-selectonemenu {
    vertical-align: middle;
    border: 1px solid #ced4da;
    position: relative;
    padding: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 4px;
    background: #fafdff
}

body .ui-selectonemenu .ui-selectonemenu-label {
    background: rgba(0, 0, 0, 0);
    padding-right: 2.357rem
}

body .ui-selectonemenu .ui-selectonemenu-trigger {
    border: 0 none;
    cursor: pointer;
    height: 100%;
    position: absolute;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 2.357rem;
    background: rgba(0, 0, 0, 0);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-selectonemenu .ui-selectonemenu-trigger .ui-icon {
    position: static;
    margin: 0;
    color: #288d2c;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-selectonemenu .ui-selectonemenu-trigger .ui-icon:before {
    content: ""
}

body .ui-selectonemenu.ui-state-hover {
    border-color: #288d2c
}

body .ui-selectonemenu.ui-state-focus {
    border-color: #065dea;
    outline: 0 none;
    box-shadow: 0 0 0 .2em #82b5ff
}

body .ui-selectonemenu.ui-state-error {
    border-color: #f44336
}

body .ui-fluid .ui-selectonemenu-label {
    width: calc(100% - 2.357rem);
    padding: .5rem .5rem
}

body .ui-fluid .ui-selectonemenu .ui-selectonemenu-trigger {
    width: 2.357rem;
    padding: 0
}

body.ui-input-filled .ui-selectonemenu,
body .ui-input-filled .ui-selectonemenu {
    background: #fafdff
}

body.ui-input-filled .ui-selectonemenu.ui-state-hover,
body .ui-input-filled .ui-selectonemenu.ui-state-hover {
    background-color: #f8f9fa
}

body.ui-input-filled .ui-selectonemenu.ui-state-focus,
body .ui-input-filled .ui-selectonemenu.ui-state-focus {
    background-color: #f8f9fa
}

body .ui-selectonemenu-panel {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 4px
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container {
    padding: .5rem 1rem;
    margin: 0;
    border-bottom: 0 none;
    background: #f8f9fa;
    color: #495057
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container .ui-selectonemenu-filter.ui-inputfield {
    padding: .5rem .5rem;
    width: 100%;
    padding-left: 2rem
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    right: auto;
    bottom: auto;
    margin-top: -0.5rem;
    color: #288d2c
}

body .ui-selectonemenu-panel .ui-selectonemenu-filter-container .ui-icon:before {
    content: ""
}

body .ui-selectonemenu-panel .ui-selectonemenu-items {
    padding: .5rem 0
}

body .ui-selectonemenu-panel .ui-selectonemenu-items.ui-selectonemenu-table>thead tr th {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    color: #495057;
    font-weight: 700
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item {
    margin: 0;
    padding: .5rem 1rem;
    background: rgba(0, 0, 0, 0);
    color: #495057;
    outline: 0 none;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-selectonemenu-row {
    background: #fff;
    color: #495057;
    border: 1px solid rgba(0, 0, 0, .08)
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-selectonemenu-row>td {
    padding: 1rem 1rem
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-selectonemenu-row:first-child {
    border-top-color: rgba(0, 0, 0, 0)
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item.ui-state-highlight {
    background: #e6eef9;
    color: #495057;
    overflow: auto
}

body .ui-selectonemenu-panel .ui-selectonemenu-items .ui-selectonemenu-item-group {
    background: #fff;
    margin: 0;
    padding: .75rem 1rem;
    color: #495057
}

body .ui-slider {
    background: #d8dee4
}

body .ui-slider .ui-slider-handle {
    width: 1.143rem;
    height: 1.143rem;
    background: #fff;
    border: 2px solid #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%
}

body .ui-slider .ui-slider-handle.ui-state-hover {
    border-color: #288d2c;
    background: #288d2c
}

body .ui-slider .ui-slider-handle.ui-state-focus {
    outline: 0 none
}

body .ui-slider.ui-slider-horizontal {
    height: .286rem
}

body .ui-slider.ui-slider-horizontal .ui-slider-handle {
    top: -0.4285rem;
    margin-left: -0.5715rem
}

body .ui-slider.ui-slider-vertical {
    width: .286rem
}

body .ui-slider.ui-slider-vertical .ui-slider-handle {
    left: -0.4285rem;
    margin-top: -0.5715rem
}

body .ui-slider .ui-slider-range {
    background: #288d2c
}

body .ui-inputtext+.ui-slider {
    margin-bottom: .5rem
}

body .ui-spinner .ui-spinner-input {
    padding-right: 2.857rem
}

body .ui-spinner .ui-spinner-button.ui-button {
    position: absolute;
    border-radius: 0;
    width: 2.357rem;
    z-index: 1
}

body .ui-spinner .ui-spinner-button.ui-button .ui-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.5rem;
    margin-top: -0.5rem
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-up {
    border-top-right-radius: 4px
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-up .ui-icon-triangle-1-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-up .ui-icon-triangle-1-n:before {
    content: ""
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-down {
    border-bottom-right-radius: 4px
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-down .ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-spinner .ui-spinner-button.ui-button.ui-spinner-down .ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-fluid .ui-spinner .ui-spinner-input {
    padding-right: 2.857rem
}

.ui-texteditor .ui-editor-toolbar {
    background: #f8f9fa;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.ui-texteditor .ui-editor-toolbar.ql-snow {
    border: 1px solid #d8dee4
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-stroke {
    stroke: #288d2c
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-fill {
    fill: #288d2c
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #288d2c
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #495057
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #495057
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #495057
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #495057
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #495057
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #495057
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    padding: .5rem 0
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #495057
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #495057;
    background: #e9ecef
}

.ui-texteditor .ui-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: .5rem 1rem
}

.ui-texteditor .ql-container {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.ui-texteditor .ql-container.ql-snow {
    border: 1px solid #d8dee4
}

.ui-texteditor .ql-container .ql-editor {
    background: #fafdff;
    color: #495057;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.ui-texteditor .ql-snow.ql-toolbar button:hover,
.ui-texteditor .ql-snow.ql-toolbar button:focus {
    color: #495057
}

.ui-texteditor .ql-snow.ql-toolbar button:hover .ql-stroke,
.ui-texteditor .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #495057
}

.ui-texteditor .ql-snow.ql-toolbar button:hover .ql-fill,
.ui-texteditor .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #495057
}

.ui-texteditor .ql-snow.ql-toolbar button.ql-active,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #288d2c
}

.ui-texteditor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #288d2c
}

.ui-texteditor .ql-snow.ql-toolbar button.ql-active .ql-fill,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #288d2c
}

.ui-texteditor .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.ui-texteditor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #288d2c
}

.ui-texteditor.ui-state-error .ui-editor-toolbar.ql-snow,
.ui-texteditor.ui-state-error .ql-container.ql-snow {
    border-color: #f44336
}

body .ui-toggleswitch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.75rem
}

body .ui-toggleswitch .ui-toggleswitch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d8dee4;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border: solid rgba(0, 0, 0, 0);
    border-width: 2px;
    border-radius: 30px
}

body .ui-toggleswitch .ui-toggleswitch-slider.ui-state-error {
    border-color: #f44336
}

body .ui-toggleswitch .ui-toggleswitch-slider:before,
body .ui-toggleswitch .ui-toggleswitch-handler {
    position: absolute;
    top: 50%;
    background: #fff;
    color: #495057;
    width: 1.25rem;
    height: 1.25rem;
    left: calc(.25rem - 2px);
    margin-top: -0.625rem;
    border-radius: 50%;
    transition: all .2s;
    font-size: .625rem;
    line-height: 1.25rem
}

body .ui-toggleswitch .ui-toggleswitch-handler span {
    font-size: .625rem;
    line-height: 1.25rem
}

body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-slider:before,
body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-handler {
    color: #495057;
    transform: translateX(1.25rem)
}

body .ui-toggleswitch.ui-toggleswitch-focus .ui-toggleswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-slider {
    background: #288d2c
}

body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-slider:before,
body .ui-toggleswitch.ui-toggleswitch-checked .ui-toggleswitch-slider .ui-toggleswitch-handler {
    background: #fff
}

body .ui-breadcrumb {
    background: #fff;
    border: 1px solid #d8dee4;
    border-radius: 4px;
    padding: 1rem
}

body .ui-breadcrumb ul li {
    float: none;
    display: inline-block;
    vertical-align: middle
}

body .ui-breadcrumb ul li .ui-menuitem-link {
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 4px;
    margin: 0
}

body .ui-breadcrumb ul li .ui-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-breadcrumb ul li .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb ul li .ui-menuitem-link .ui-menuitem-icon {
    color: #288d2c
}

body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin: 0 .5rem 0 .5rem;
    color: #495057
}

body .ui-breadcrumb ul li.ui-breadcrumb-chevron:before {
    content: ""
}

body .ui-breadcrumb ul li:not(.ui-breadcrumb-chevron) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

body .ui-breadcrumb ul li:not(.ui-breadcrumb-chevron):before {
    content: "";
    font-family: "primeicons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0 !important;
    background-image: none !important;
    margin: 0 .5rem 0 .5rem;
    color: #495057
}

body .ui-breadcrumb ul li:first-child:not(.ui-breadcrumb-chevron):before {
    display: none
}

body .ui-breadcrumb ul li:first-child a.ui-icon-home {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    color: #288d2c;
    margin: 0
}

body .ui-breadcrumb ul li:first-child a.ui-icon-home:before {
    content: ""
}

body .ui-breadcrumb ul li:first-child a.ui-icon-home span {
    display: none
}

body .ui-breadcrumb ul li:last-child .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb ul li:last-child .ui-menuitem-link .ui-menuitem-icon {
    color: #288d2c
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link {
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 4px;
    margin: 0
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb .ui-breadcrumb-items li .ui-menuitem-link .ui-menuitem-icon {
    color: #288d2c
}

body .ui-breadcrumb .ui-breadcrumb-items li.ui-breadcrumb-chevron {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin: 0 .5rem 0 .5rem;
    color: #495057
}

body .ui-breadcrumb .ui-breadcrumb-items li.ui-breadcrumb-chevron:before {
    content: ""
}

body .ui-breadcrumb .ui-breadcrumb-items li:not(.ui-breadcrumb-chevron) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

body .ui-breadcrumb .ui-breadcrumb-items li:not(.ui-breadcrumb-chevron):before {
    content: "";
    font-family: "primeicons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0 !important;
    background-image: none !important;
    margin: 0 .5rem 0 .5rem;
    color: #495057
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child:not(.ui-breadcrumb-chevron):before {
    display: none
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child a.ui-icon-home {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    color: #288d2c;
    margin: 0
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child a.ui-icon-home:before {
    content: ""
}

body .ui-breadcrumb .ui-breadcrumb-items li:first-child a.ui-icon-home span {
    display: none
}

body .ui-breadcrumb .ui-breadcrumb-items li:last-child .ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-breadcrumb .ui-breadcrumb-items li:last-child .ui-menuitem-link .ui-menuitem-icon {
    color: #288d2c
}

body .ui-menu {
    padding: .25rem 0;
    background: #fff;
    border: 1px solid #d8dee4;
    border-radius: 4px
}

body .ui-menu.ui-shadow {
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon {
    position: static;
    margin-right: .5rem
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-e:before {
    content: ""
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header .ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-menu.ui-menu-toggleable .ui-menu-list .ui-widget-header h3 {
    font-size: 1rem;
    padding-left: 0
}

body .ui-menu .ui-menu-list .ui-widget-header {
    margin: 0;
    width: 100%;
    background: #fff;
    color: #495057;
    float: none;
    border: 0 none;
    padding: .75rem 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 0
}

body .ui-menu .ui-menu-list .ui-widget-header h3 {
    padding: 0;
    font-size: 1rem;
    font-weight: 700;
    color: #495057;
    float: none
}

body .ui-menu .ui-menu-list .ui-widget-header.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-menu .ui-menu-list .ui-widget-header.ui-state-hover .ui-icon {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem {
    margin: 0;
    width: 100%;
    border: 0 none;
    float: none;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link {
    padding: .75rem 1rem;
    width: 100%;
    border: 0 none;
    float: none;
    border-radius: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link.ui-state-hover {
    background: #e9ecef
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link.ui-state-hover .ui-icon {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link.ui-state-hover .ui-menuitem-text {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
    margin-right: .5rem;
    vertical-align: middle;
    float: none;
    color: #288d2c
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-menuitem-text {
    float: none;
    vertical-align: middle;
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-e:last-child {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: relative;
    margin-right: -0.5rem;
    color: #288d2c
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-e:last-child:before {
    content: ""
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-s:last-child {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin-right: -0.5rem;
    color: #288d2c
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link .ui-icon-triangle-1-s:last-child:before {
    content: ""
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-state-hover>.ui-menuitem-link {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-state-hover>.ui-menuitem-link .ui-icon {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link {
    background: #e9ecef
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link .ui-menuitem-text {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link .ui-menuitem-icon,
body .ui-menu .ui-menu-list .ui-menuitem.ui-menuitem-active>.ui-menuitem-link .ui-icon {
    color: #495057
}

body .ui-menu .ui-menu-list .ui-menu-child {
    background: #fff;
    padding: .25rem 0
}

body .ui-menu .ui-menu-list .ui-menu-child.ui-shadow {
    border-radius: 4px;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

body .ui-menu .ui-menu-list .ui-separator {
    width: 100%;
    margin: .25rem 0;
    border-top: 1px solid #d8dee4
}

body .ui-menu.ui-menubar {
    padding: .5rem;
    background: #f8f9fa;
    border-radius: 4px
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem {
    display: inline-block;
    width: auto
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link {
    border-radius: 4px
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link .ui-menuitem-icon {
    position: static;
    vertical-align: middle
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link>span {
    float: none;
    display: inline-block
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem>.ui-menuitem-link .ui-icon-triangle-1-s:last-child {
    margin-left: .5rem;
    vertical-align: middle
}

body .ui-menu.ui-menubar>.ui-menu-list>.ui-menuitem.ui-menubar-options {
    float: right;
    margin-right: .5rem
}

body .ui-menu.ui-slidemenu .ui-slidemenu-backward {
    padding: .75rem 1rem;
    color: #495057;
    width: 100%;
    border: 0 none;
    background: #fff;
    margin-bottom: 0;
    border-radius: 0
}

body .ui-menu.ui-slidemenu .ui-slidemenu-backward .ui-icon-triangle-1-w {
    position: relative;
    top: 2px;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin-right: .5rem
}

body .ui-menu.ui-slidemenu .ui-slidemenu-backward .ui-icon-triangle-1-w:before {
    content: ""
}

body .ui-menu.ui-slidemenu .ui-menu-child {
    padding: 0
}

body .ui-menu.ui-slidemenu .ui-menu-child.ui-shadow {
    box-shadow: none;
    border: 0 none
}

body .ui-menu.ui-megamenu .ui-widget-header {
    margin: 0;
    width: 100%;
    background: #fff;
    color: #495057;
    float: none;
    border: 0 none;
    padding: .75rem 1rem;
    font-weight: 700;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 0
}

body .ui-menu.ui-megamenu .ui-widget-header span {
    float: none;
    padding: 0
}

body .ui-menu.ui-megamenu .ui-menu-list>table>tbody>tr>td {
    padding: 0
}

body .ui-menu.ui-megamenu .ui-menu-list .ui-menuitem-link {
    border-radius: 4px
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical {
    background: #f8f9fa;
    padding: .25rem 0
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical>.ui-menu-list .ui-menuitem {
    width: 100%
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical>.ui-menu-list .ui-menuitem>.ui-menuitem-link {
    border-radius: 0
}

body .ui-menu.ui-megamenu.ui-megamenu-vertical>.ui-menu-list>.ui-menuitem>.ui-menuitem-link .ui-icon:last-child {
    float: right
}

body .ui-menu .ui-divider.ui-widget.ui-divider-horizontal {
    margin: 0
}

body .ui-panelmenu .ui-icon {
    position: static
}

body .ui-panelmenu .ui-panelmenu-panel:first-child .ui-panelmenu-header {
    margin-top: 0;
    border-top: 1px solid #d8dee4;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

body .ui-panelmenu .ui-panelmenu-panel:last-child .ui-panelmenu-header {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-panelmenu .ui-panelmenu-panel:last-child .ui-panelmenu-header.ui-state-active {
    border-radius: 0
}

body .ui-panelmenu h3.ui-panelmenu-header {
    border: 1px solid #d8dee4;
    border-top: 0 none;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    font-weight: 700;
    margin: 0 0 0 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    user-select: none;
    border-radius: 0;
    position: relative;
    outline: 0 none;
    font-size: 1rem
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon {
    position: static;
    margin: 0;
    vertical-align: middle;
    margin-right: .5rem
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-e:before {
    content: ""
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panelmenu h3.ui-panelmenu-header .ui-icon.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-panelmenu h3.ui-panelmenu-header a {
    display: inline;
    padding: 0;
    outline: 0 none;
    color: inherit;
    vertical-align: middle;
    text-decoration: none
}

body .ui-panelmenu h3.ui-panelmenu-header.ui-state-hover {
    border-color: #d8dee4;
    background: #e9ecef;
    color: #495057
}

body .ui-panelmenu h3.ui-panelmenu-header.ui-state-active {
    border-color: #d8dee4;
    background: #f8f9fa;
    color: #495057
}

body .ui-panelmenu h3.ui-panelmenu-header.ui-state-active:hover {
    border-color: #d8dee4;
    background: #e9ecef;
    color: #495057
}

body .ui-panelmenu .ui-panelmenu-content {
    padding: .25rem 0;
    background: #fff;
    border: 1px solid #d8dee4;
    margin-top: 0;
    position: static;
    border-top: 0 none
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link {
    padding: .75rem 1rem;
    color: #495057;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover {
    background: #e9ecef
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover .ui-icon,
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover .ui-panelmenu-icon {
    color: #495057
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link.ui-state-hover .ui-menuitem-text {
    color: #495057
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: .5rem;
    position: static;
    color: #288d2c
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-e:before {
    content: ""
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link>span.ui-icon.ui-panelmenu-icon.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link .ui-menuitem-text {
    display: inline-block;
    vertical-align: middle
}

body .ui-steps {
    position: relative
}

body .ui-steps ul {
    display: -ms-flexbox;
    display: flex
}

body .ui-steps .ui-steps-item {
    flex: 1 1 0;
    -ms-flex: 1 1 0px;
    background: rgba(0, 0, 0, 0);
    text-align: center;
    float: none;
    opacity: 1
}

body .ui-steps .ui-steps-item .ui-menuitem-link {
    display: block;
    text-align: center;
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
    padding: 0
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
    color: #495057;
    background: #fff;
    border: 1px solid #e9ecef;
    width: 2rem;
    height: 2rem;
    font-size: 1.143rem;
    padding: 0;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
}

body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
    display: block;
    padding-top: .5rem;
    color: #288d2c
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
    background: #e6eef9;
    color: #495057;
    border-color: #e6eef9
}

body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
    font-weight: 600;
    color: #495057
}

body .ui-steps .ui-steps-item:not(.ui-state-highlight) {
    opacity: .65
}

body .ui-steps:before {
    content: " ";
    border-top: 1px solid #d8dee4;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute
}

body .ui-tabmenu {
    padding: 0;
    position: static
}

body .ui-tabmenu .ui-tabmenu-nav {
    margin: 0;
    padding: 0;
    background: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: relative;
    overflow-x: scroll
}

body .ui-tabmenu .ui-tabmenu-nav:before {
    position: absolute;
    border-bottom: 2px solid #d8dee4;
    left: 0;
    bottom: 0;
    width: 100%
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
    list-style: none;
    padding: 0;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    float: none;
    background: #fff;
    color: #288d2c;
    top: 0;
    margin: 0;
    border-bottom: 2px solid #d8dee4;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    flex-shrink: 0
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a {
    color: #288d2c;
    padding: 1rem;
    font-weight: 700;
    display: block;
    line-height: 1;
    outline: 0 none
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a .ui-icon {
    margin-right: .5rem
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover {
    background: #fff;
    border-color: #adb5bd
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover a {
    color: #288d2c
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover a .ui-icon {
    color: #288d2c
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
    background: #fff;
    border-color: #288d2c
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active a {
    color: #288d2c
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active a .ui-icon {
    color: #288d2c
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-menuitem-outline {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-tabmenu.ui-tabs-bottom .ui-tabmenu-nav:before {
    border-bottom: 0 none;
    border-top: 2px solid #d8dee4;
    bottom: auto;
    top: 0
}

body .ui-tabmenu.ui-tabs-bottom .ui-tabmenu-nav .ui-tabmenuitem {
    border-bottom: 0 none;
    border-top: 2px solid #d8dee4
}

body .ui-tabmenu.ui-tabs-bottom .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabmenu.ui-tabs-bottom .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
    border-color: #288d2c
}

body .ui-tabmenu.ui-tabs-left .ui-tabmenu-nav {
    flex-direction: column
}

body .ui-tabmenu.ui-tabs-left .ui-tabmenu-nav:before {
    border-bottom: 0 none;
    border-right: 2px solid #d8dee4;
    left: auto;
    right: 0;
    width: auto;
    height: 100%
}

body .ui-tabmenu.ui-tabs-left .ui-tabmenu-nav .ui-tabmenuitem {
    border-bottom: 0 none;
    border-right: 2px solid #d8dee4
}

body .ui-tabmenu.ui-tabs-left .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabmenu.ui-tabs-left .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
    border-color: #288d2c
}

body .ui-tabmenu.ui-tabs-right .ui-tabmenu-nav {
    flex-direction: column
}

body .ui-tabmenu.ui-tabs-right .ui-tabmenu-nav:before {
    border-bottom: 0 none;
    border-left: 2px solid #d8dee4;
    width: auto;
    height: 100%
}

body .ui-tabmenu.ui-tabs-right .ui-tabmenu-nav .ui-tabmenuitem {
    border-bottom: 0 none;
    border-left: 2px solid #d8dee4
}

body .ui-tabmenu.ui-tabs-right .ui-tabmenu-nav .ui-tabmenuitem.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabmenu.ui-tabs-right .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
    border-color: #288d2c
}

body .ui-growl {
    top: 85px
}

body .ui-growl .ui-growl-item-container {
    margin: 0 0 1rem 0;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-growl .ui-growl-item-container .ui-icon-closethick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    position: absolute;
    top: .5rem;
    right: .5rem
}

body .ui-growl .ui-growl-item-container .ui-icon-closethick:before {
    content: ""
}

body .ui-growl .ui-growl-item-container .ui-growl-item {
    padding: 1rem;
    min-height: 70px
}

body .ui-growl .ui-growl-item-container.ui-growl-info {
    background: #8ac2ff;
    border: 1px solid #03a9f4;
    color: #01579b;
    border-width: 0 0 0 6px
}

body .ui-growl .ui-growl-item-container.ui-growl-info .ui-growl-image {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 2rem;
    color: #01579b
}

body .ui-growl .ui-growl-item-container.ui-growl-info .ui-growl-image:before {
    content: ""
}

body .ui-growl .ui-growl-item-container.ui-growl-info .ui-icon {
    color: #01579b
}

body .ui-growl .ui-growl-item-container.ui-growl-warn {
    background: #ffecb3;
    border: 1px solid #ffc107;
    color: #7f6003;
    border-width: 0 0 0 6px
}

body .ui-growl .ui-growl-item-container.ui-growl-warn .ui-growl-image {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 2rem;
    color: #7f6003
}

body .ui-growl .ui-growl-item-container.ui-growl-warn .ui-growl-image:before {
    content: ""
}

body .ui-growl .ui-growl-item-container.ui-growl-warn .ui-icon {
    color: #7f6003
}

body .ui-growl .ui-growl-item-container.ui-growl-error,
body .ui-growl .ui-growl-item-container.ui-growl-fatal {
    background: #ffcdd2;
    border: 1px solid #ef5350;
    color: #b71c1c;
    border-width: 0 0 0 6px
}

body .ui-growl .ui-growl-item-container.ui-growl-error .ui-growl-image,
body .ui-growl .ui-growl-item-container.ui-growl-fatal .ui-growl-image {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 2rem;
    color: #b71c1c
}

body .ui-growl .ui-growl-item-container.ui-growl-error .ui-growl-image:before,
body .ui-growl .ui-growl-item-container.ui-growl-fatal .ui-growl-image:before {
    content: ""
}

body .ui-growl .ui-growl-item-container.ui-growl-error .ui-icon,
body .ui-growl .ui-growl-item-container.ui-growl-fatal .ui-icon {
    color: #b71c1c
}

body .ui-message {
    padding: .5rem .5rem;
    display: inline-block;
    border-radius: 4px
}

body .ui-message.ui-message-info {
    background: #8ac2ff;
    border: 1px solid #03a9f4;
    border-width: 0 0 0 3px;
    color: #01579b
}

body .ui-message.ui-message-info .ui-message-info-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin: 0;
    color: #01579b;
    float: none;
    width: auto;
    height: auto;
    position: relative;
    top: 1px
}

body .ui-message.ui-message-info .ui-message-info-icon:before {
    content: ""
}

body .ui-message.ui-message-info .ui-message-info-detail {
    margin-left: .5rem
}

body .ui-message.ui-message-warn {
    background: #ffecb3;
    border: 1px solid #ffc107;
    border-width: 0 0 0 3px;
    color: #7f6003
}

body .ui-message.ui-message-warn .ui-message-warn-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin: 0;
    color: #7f6003;
    float: none;
    width: auto;
    height: auto;
    position: relative;
    top: 1px
}

body .ui-message.ui-message-warn .ui-message-warn-icon:before {
    content: ""
}

body .ui-message.ui-message-warn .ui-message-warn-detail {
    margin-left: .5rem
}

body .ui-message.ui-message-error,
body .ui-message.ui-message-fatal {
    background: #ffcdd2;
    border: 1px solid #ef5350;
    border-width: 0 0 0 3px;
    color: #b71c1c
}

body .ui-message.ui-message-error .ui-message-error-icon,
body .ui-message.ui-message-fatal .ui-message-error-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin: 0;
    color: #b71c1c;
    float: none;
    width: auto;
    height: auto;
    position: relative;
    top: 1px
}

body .ui-message.ui-message-error .ui-message-error-icon:before,
body .ui-message.ui-message-fatal .ui-message-error-icon:before {
    content: ""
}

body .ui-message.ui-message-error .ui-message-error-detail,
body .ui-message.ui-message-fatal .ui-message-error-detail {
    margin-left: .5rem
}

body .ui-message.ui-message-icon-only {
    width: auto;
    height: auto
}

body .ui-widget+.ui-message {
    margin: 0 0 0 .5rem
}

body .ui-messages {
    padding: 0;
    margin: 0;
    font-weight: 600;
    position: relative;
    border-radius: 4px
}

body .ui-messages>div {
    padding: 1.5rem;
    margin: 1rem 0;
    border-radius: 4px
}

body .ui-messages ul {
    margin: 0
}

body .ui-messages ul .ui-messages-info-summary,
body .ui-messages ul .ui-messages-warn-summary,
body .ui-messages ul .ui-messages-error-summary,
body .ui-messages ul .ui-messages-fatal-summary {
    margin: 0 .5rem 0 0
}

body .ui-messages ul .ui-messages-info-detail,
body .ui-messages ul .ui-messages-warn-detail,
body .ui-messages ul .ui-messages-error-detail,
body .ui-messages ul .ui-messages-fatal-detail {
    margin: 0;
    overflow-wrap: break-word
}

body .ui-messages .ui-messages-close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none;
    text-align: center;
    transition: background-color .2s
}

body .ui-messages .ui-messages-close:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-messages .ui-messages-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-messages .ui-messages-close .ui-icon-close {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-messages .ui-messages-close .ui-icon-close:before {
    content: ""
}

body .ui-messages .ui-messages-info {
    background: #8ac2ff;
    border: 1px solid #03a9f4;
    border-width: 0 0 0 6px;
    color: #01579b
}

body .ui-messages .ui-messages-info .ui-messages-info-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 1.5rem;
    margin: 0 .5rem 0 0;
    color: #01579b
}

body .ui-messages .ui-messages-info .ui-messages-info-icon:before {
    content: ""
}

body .ui-messages .ui-messages-info .ui-messages-close {
    background: #8ac2ff;
    color: #01579b
}

body .ui-messages .ui-messages-info .ui-messages-close:hover {
    background: #288d2c
}

body .ui-messages .ui-messages-warn {
    background: #ffecb3;
    border: 1px solid #ffc107;
    border-width: 0 0 0 6px;
    color: #7f6003
}

body .ui-messages .ui-messages-warn .ui-messages-warn-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 1.5rem;
    margin: 0 .5rem 0 0;
    color: #7f6003
}

body .ui-messages .ui-messages-warn .ui-messages-warn-icon:before {
    content: ""
}

body .ui-messages .ui-messages-warn .ui-messages-close {
    background: #ffecb3;
    color: #7f6003
}

body .ui-messages .ui-messages-warn .ui-messages-close:hover {
    background: #ffe69a
}

body .ui-messages .ui-messages-error,
body .ui-messages .ui-messages-fatal {
    background: #ffcdd2;
    border: 1px solid #ef5350;
    border-width: 0 0 0 6px;
    color: #b71c1c
}

body .ui-messages .ui-messages-error .ui-messages-error-icon,
body .ui-messages .ui-messages-error .ui-messages-fatal,
body .ui-messages .ui-messages-fatal .ui-messages-error-icon,
body .ui-messages .ui-messages-fatal .ui-messages-fatal {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 1.5rem;
    margin: 0 .5rem 0 0;
    color: #b71c1c
}

body .ui-messages .ui-messages-error .ui-messages-error-icon:before,
body .ui-messages .ui-messages-error .ui-messages-fatal:before,
body .ui-messages .ui-messages-fatal .ui-messages-error-icon:before,
body .ui-messages .ui-messages-fatal .ui-messages-fatal:before {
    content: ""
}

body .ui-messages .ui-messages-error .ui-messages-close,
body .ui-messages .ui-messages-fatal .ui-messages-close {
    background: #ffcdd2;
    color: #b71c1c
}

body .ui-messages .ui-messages-error .ui-messages-close:hover,
body .ui-messages .ui-messages-fatal .ui-messages-close:hover {
    background: #ffb4bb
}

body .ui-avatar {
    background-color: #d8dee4;
    border-radius: 4px
}

body .ui-avatar.ui-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem
}

body .ui-avatar.ui-avatar-lg .ui-avatar-icon {
    font-size: 1.5rem
}

body .ui-avatar.ui-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem
}

body .ui-avatar.ui-avatar-xl .ui-avatar-icon {
    font-size: 2rem
}

body .ui-avatar-group .ui-avatar {
    border: 2px solid #fff
}

body .ui-badge {
    background: #288d2c;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem
}

body .ui-badge:not(.ui-widget) {
    display: inline-block;
    border-radius: 50%;
    text-align: center
}

body .ui-badge.ui-badge-secondary {
    background: #6d35d0;
    color: #fff
}

body .ui-badge.ui-badge-success {
    background: #689f38;
    color: #fff
}

body .ui-badge.ui-badge-info {
    background: #787878;
    color: #fff
}

body .ui-badge.ui-badge-warning {
    background: #fbc02d;
    color: #212529
}

body .ui-badge.ui-badge-danger {
    background: #d32f2f;
    color: #fff
}

body .ui-badge.ui-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem
}

body .ui-badge.ui-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem
}

body .ui-overlay-badge {
    position: relative;
    display: inline-block
}

body .ui-overlay-badge .ui-badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    margin: 0
}

body .ui-chip {
    background-color: #e6eef9;
    color: #495057;
    border-radius: 16px;
    padding: 0 .5rem
}

body .ui-chip .ui-chip-text {
    line-height: 1.5;
    margin-top: .25rem;
    margin-bottom: .25rem
}

body .ui-chip .ui-chip-icon {
    margin-right: .5rem
}

body .ui-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: .5rem
}

body .ui-chip .ui-chip-remove-icon {
    margin-left: .5rem;
    border-radius: 4px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-chip .ui-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-clock {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-galleria .ui-galleria-close {
    margin: .5rem;
    background: rgba(0, 0, 0, 0);
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    border-radius: 50%
}

body .ui-galleria .ui-galleria-close .ui-galleria-close-icon {
    font-size: 2rem
}

body .ui-galleria .ui-galleria-close:hover {
    background: rgba(255, 255, 255, .1);
    color: #f8f9fa
}

body .ui-galleria .ui-galleria-item-nav {
    background: rgba(0, 0, 0, 0);
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    border-radius: 4px;
    margin: 0 .5rem
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-prev-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 2rem
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-prev-icon:before {
    content: ""
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-next-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    font-size: 2rem
}

body .ui-galleria .ui-galleria-item-nav .ui-galleria-item-next-icon:before {
    content: ""
}

body .ui-galleria .ui-galleria-item-nav:not(.ui-state-disabled):hover {
    background: rgba(255, 255, 255, .1);
    color: #f8f9fa
}

body .ui-galleria .ui-galleria-caption-item {
    background: rgba(0, 0, 0, .5);
    color: #f8f9fa;
    padding: 1rem
}

body .ui-galleria .ui-galleria-indicators {
    padding: 1rem
}

body .ui-galleria .ui-galleria-indicators .ui-galleria-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    border-radius: 50%
}

body .ui-galleria .ui-galleria-indicators .ui-galleria-indicator button:hover {
    background: #d8dee4
}

body .ui-galleria .ui-galleria-indicators .ui-galleria-indicator.ui-state-highlight button {
    background: #288d2c;
    color: #fff
}

body .ui-galleria.ui-galleria-indicators-bottom .ui-galleria-indicator,
body .ui-galleria.ui-galleria-indicators-top .ui-galleria-indicator {
    margin-right: .5rem
}

body .ui-galleria.ui-galleria-indicators-left .ui-galleria-indicator,
body .ui-galleria.ui-galleria-indicators-right .ui-galleria-indicator {
    margin-bottom: .5rem
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators {
    background: rgba(0, 0, 0, .5)
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators .ui-galleria-indicator button {
    background: rgba(255, 255, 255, .4)
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators .ui-galleria-indicator button:hover {
    background: rgba(255, 255, 255, .6)
}

body .ui-galleria.ui-galleria-indicator-onitem .ui-galleria-indicators .ui-galleria-indicator.ui-state-highlight button {
    background: #e6eef9;
    color: #495057
}

body .ui-galleria .ui-galleria-thumbnail-container {
    background: rgba(0, 0, 0, .9);
    padding: .8rem .25rem
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next {
    margin: .5rem;
    background-color: rgba(0, 0, 0, 0);
    color: #f8f9fa;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s ease-in-out;
    border-radius: 50%
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev:hover,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, .1);
    color: #f8f9fa
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-e,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-e:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-e:before {
    content: ""
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-s,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-s:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-s:before {
    content: ""
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-w,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-w {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-w:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-w:before {
    content: ""
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-n,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-n {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-prev .ui-icon-circle-triangle-n:before,
body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-next .ui-icon-circle-triangle-n:before {
    content: ""
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content {
    transition: box-shadow .2s;
    margin: .2rem;
    margin-left: 0;
    margin-right: 0
}

body .ui-galleria .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-galleria.ui-galleria-thumbnails-left .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content,
body .ui-galleria.ui-galleria-thumbnails-right .ui-galleria-thumbnail-container .ui-galleria-thumbnail-item-content {
    margin: .2rem
}

body .ui-galleria .ui-galleria-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

.ui-galleria-mask.ui-widget-overlay {
    background-color: rgba(0, 0, 0, .9)
}

body .ui-log {
    padding: 0;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
    border: 0 none;
    border-radius: 4px;
    height: auto
}

body .ui-log .ui-log-header {
    background: #fff;
    color: #495057;
    font-weight: 700;
    border-bottom: 0 none;
    padding: 1.5rem;
    height: auto
}

body .ui-log .ui-log-header .ui-log-button {
    display: inline-block;
    position: static;
    margin-right: .5rem;
    padding: 2px;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-log .ui-log-header .ui-log-button:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-log .ui-log-header .ui-log-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-trash {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-trash:before {
    content: ""
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-note {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-note:before {
    content: ""
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-info {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-info:before {
    content: ""
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-notice {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-notice:before {
    content: ""
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-search {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-search:before {
    content: ""
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-alert {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-log .ui-log-header .ui-log-button .ui-icon-alert:before {
    content: ""
}

body .ui-log .ui-log-content {
    background: #fff;
    color: #495057;
    border: 0 none;
    padding: .2rem 1.5rem 2rem 1.5rem;
    border: 0 none;
    overflow: auto
}

body .ui-progressbar {
    border: 0 none;
    height: 1rem;
    background: #efefef;
    border-radius: 4px
}

body .ui-progressbar .ui-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #288d2c;
    border-radius: 4px
}

body .ui-progressbar .ui-progressbar-label {
    margin-top: 0;
    color: #495057;
    line-height: 1rem
}

body .ui-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    background: rgba(0, 0, 0, .7)
}

body .ui-scrolltop:hover {
    background: rgba(0, 0, 0, .8)
}

body .ui-scrolltop .ui-scrolltop-icon {
    font-size: 1.5rem;
    color: #f8f9fa
}

body .ui-skeleton {
    background-color: #e9ecef;
    border-radius: 4px
}

body .ui-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))
}

body .ui-tag {
    background: #288d2c;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    padding: .25rem .4rem;
    border-radius: 4px
}

body .ui-tag:not(.ui-widget) {
    display: inline-block;
    text-align: center;
    line-height: 1
}

body .ui-tag.ui-tag-success {
    background: #689f38;
    color: #fff
}

body .ui-tag.ui-tag-info {
    background: #787878;
    color: #fff
}

body .ui-tag.ui-tag-warning {
    background: #fbc02d;
    color: #212529
}

body .ui-tag.ui-tag-danger {
    background: #d32f2f;
    color: #fff
}

body .ui-tag.ui-tag-rounded {
    border-radius: 10rem
}

body .ui-tag .ui-tag-icon {
    margin-right: .25rem;
    font-size: .75rem
}

body .ui-terminal {
    background: #fff;
    color: #495057;
    border: 1px solid #d8dee4;
    padding: 1rem
}

body .ui-confirm-popup {
    background: #fff;
    color: #495057;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-confirm-popup .ui-confirm-popup-content {
    padding: 1rem
}

body .ui-confirm-popup .ui-confirm-popup-footer {
    text-align: right;
    padding: 0 1rem 1rem 1rem
}

body .ui-confirm-popup .ui-confirm-popup-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

body .ui-confirm-popup .ui-confirm-popup-footer button:last-child {
    margin: 0
}

body .ui-confirm-popup:after {
    border-style: solid;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

body .ui-confirm-popup:before {
    border-style: solid;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

body .ui-confirm-popup.ui-confirm-popup-flipped:after {
    border-top-color: #fff
}

body .ui-confirm-popup.ui-confirm-popup-flipped:before {
    border-top-color: #fff
}

body .ui-confirm-popup .ui-confirm-popup-icon {
    font-size: 1.5rem
}

body .ui-confirm-popup .ui-confirm-popup-message {
    margin-left: 1rem
}

body .ui-dialog {
    padding: 0;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
    border: 0 none;
    border-radius: 4px
}

body .ui-dialog .ui-dialog-titlebar {
    background: #fff;
    color: #495057;
    font-weight: 700;
    border-bottom: 0 none;
    padding: 1.5rem
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
    margin: 0;
    float: none;
    font-size: 1.25rem
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
    border: 0 none;
    padding: 0;
    margin-left: .5rem;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-closethick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-closethick:before {
    content: ""
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-minus {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-minus:before {
    content: ""
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-plus {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-plus:before {
    content: ""
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-extlink {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-extlink:before {
    content: ""
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-newwin {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .ui-icon-newwin:before {
    content: ""
}

body .ui-dialog .ui-dialog-content {
    background: #fff;
    color: #495057;
    border: 0 none;
    padding: .2rem 1.5rem 2rem 1.5rem;
    border: 0 none
}

body .ui-dialog .ui-dialog-footer {
    border-top: 0 none;
    background: #fff;
    color: #495057;
    padding: 0 1.5rem 1.5rem 1.5rem;
    margin: 0;
    text-align: right
}

body .ui-dialog .ui-dialog-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content {
    padding: .2rem 1.5rem 2rem 1.5rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span {
    float: none;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin: 0 0 0 .5rem
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon {
    margin-right: .5rem;
    font-size: 2.5rem
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-warn {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-warn:before {
    content: ""
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-info {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-info:before {
    content: ""
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-error {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog.ui-confirm-dialog .ui-dialog-content>span.ui-icon.ui-icon-error:before {
    content: ""
}

body .ui-dialog.ui-resizable .ui-dialog-content {
    box-sizing: content-box
}

body .ui-dialog.ui-message-dialog .ui-dialog-content {
    padding: .2rem 1.5rem 2rem 1.5rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span {
    float: none;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin: 0 0 0 .5rem
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message {
    margin-right: .5rem;
    font-size: 2.5rem;
    width: auto;
    height: auto
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-warn-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-warn-icon:before {
    content: ""
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-info-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-info-icon:before {
    content: ""
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-error-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-error-icon:before {
    content: ""
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-fatal-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-dialog.ui-message-dialog .ui-dialog-content>span.ui-dialog-message.ui-messages-fatal-icon:before {
    content: ""
}

body .ui-lightbox {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-lightbox .ui-lightbox-caption {
    border: 1px solid #d8dee4;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    font-weight: 700
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
    color: #495057;
    margin: 0
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
    padding: 0;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close .ui-icon:before {
    content: ""
}

body .ui-lightbox .ui-lightbox-content-wrapper {
    overflow: hidden;
    background: #fff;
    color: #495057;
    border: 0 none;
    padding: 0
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    transition: all .2s;
    font-size: 24px;
    margin-left: 4px;
    color: #fff
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:before {
    content: ""
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover {
    scale: 1.2
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left span {
    display: none
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    transition: all .2s;
    font-size: 24px;
    margin-right: 4px;
    color: #fff
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:before {
    content: ""
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
    scale: 1.2
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right span {
    display: none
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content {
    border-radius: 0
}

body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content.ui-lightbox-loading+a {
    display: none
}

body .ui-overlaypanel {
    background: #fff;
    color: #495057;
    padding: 0;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
    border-radius: 4px
}

body .ui-overlaypanel .ui-overlaypanel-content {
    padding: 1rem
}

body .ui-overlaypanel .ui-overlaypanel-close {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: -1rem;
    top: -1rem;
    background: #288d2c;
    color: #fff;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    text-align: center;
    line-height: 2rem;
    padding: 0
}

body .ui-overlaypanel .ui-overlaypanel-close:hover {
    background: #0048b2;
    color: #fff
}

body .ui-overlaypanel .ui-overlaypanel-close .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    display: inline-block;
    line-height: inherit
}

body .ui-overlaypanel .ui-overlaypanel-close .ui-icon:before {
    content: ""
}

body .ui-sidebar {
    background: #fff;
    color: #495057;
    padding: 1rem;
    border: 0 none;
    box-shadow: none
}

body .ui-sidebar .ui-sidebar-close {
    padding: 0;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none
}

body .ui-sidebar .ui-sidebar-close:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-sidebar .ui-sidebar-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-sidebar .ui-sidebar-close .ui-icon {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-sidebar .ui-sidebar-close .ui-icon:before {
    content: ""
}

body .ui-sidebar.ui-sidebar-active {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

body .ui-tooltip .ui-tooltip-text {
    background: #252529;
    color: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
    border-radius: 4px
}

body .ui-tooltip.ui-state-error {
    border: 0 none
}

body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
    border-right-color: #252529
}

body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
    border-left-color: #252529
}

body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
    border-top-color: #252529
}

body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
    border-bottom-color: #252529
}

body .ui-accordion .ui-accordion-header {
    border: 1px solid #d8dee4;
    border-top: 0 none;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    font-weight: 700;
    margin-top: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    user-select: none;
    border-radius: 0;
    position: relative;
    outline: 0 none
}

body .ui-accordion .ui-accordion-header:first-child {
    margin-top: 0;
    border-top: 1px solid #d8dee4;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

body .ui-accordion .ui-accordion-header.ui-accordion-header-last {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-accordion .ui-accordion-header.ui-accordion-header-last.ui-state-active {
    border-radius: 0
}

body .ui-accordion .ui-accordion-header.ui-state-hover {
    border-color: #d8dee4;
    background: #e9ecef;
    color: #495057
}

body .ui-accordion .ui-accordion-header.ui-state-active {
    background: #f8f9fa;
    border-color: #d8dee4;
    color: #495057
}

body .ui-accordion .ui-accordion-header.ui-state-active:hover {
    border-color: #d8dee4;
    background: #e9ecef;
    color: #495057
}

body .ui-accordion .ui-accordion-header:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-accordion .ui-accordion-header.ui-state-error {
    border-color: #f44336;
    background-color: #f44336
}

body .ui-accordion .ui-accordion-header.ui-state-error.ui-state-active:hover {
    border-color: #f44336;
    background-color: #f44336;
    color: #495057
}

body .ui-accordion .ui-accordion-header.ui-state-error+.ui-accordion-content {
    border-color: #f44336
}

body .ui-accordion .ui-accordion-header>.ui-icon {
    position: static;
    display: inline-block;
    margin: 0 .5rem 0 0
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-e:before {
    content: ""
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-s {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-accordion .ui-accordion-header>.ui-icon.ui-icon-triangle-1-s:before {
    content: ""
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon {
    margin: 0;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none;
    margin-top: -0.25rem;
    margin-bottom: -0.5rem
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-closethick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-closethick:before {
    content: ""
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-minusthick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-minusthick:before {
    content: ""
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-plusthick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-plusthick:before {
    content: ""
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-gear {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-accordion .ui-accordion-header .ui-panel-titlebar-icon .ui-icon-gear:before {
    content: ""
}

body .ui-accordion .ui-accordion-content {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem;
    margin: 0;
    position: static;
    border-top: 0 none
}

body .ui-accordion .ui-accordion-content:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-card {
    background: #fff;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 4px
}

body .ui-card .ui-card-body {
    padding: 1rem
}

body .ui-card .ui-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .5rem
}

body .ui-card .ui-card-subtitle {
    font-weight: 400;
    margin-bottom: .5rem;
    color: #288d2c
}

body .ui-card .ui-card-content {
    padding: 1rem 0
}

body .ui-card .ui-card-footer {
    padding: 1rem 0 0 0
}

body .ui-dashboard .ui-sortable-placeholder {
    background-color: #e6eef9
}

body .ui-divider .ui-divider-content {
    background-color: #fff
}

body .ui-divider.ui-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem
}

body .ui-divider.ui-divider-horizontal:before {
    border-top: 1px #d8dee4
}

body .ui-divider.ui-divider-horizontal .ui-divider-content {
    padding: 0 .5rem
}

body .ui-divider.ui-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0
}

body .ui-divider.ui-divider-vertical:before {
    border-left: 1px #d8dee4
}

body .ui-divider.ui-divider-vertical .ui-divider-content {
    padding: .5rem 0
}

body .ui-fieldset {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem;
    border-radius: 4px
}

body .ui-fieldset .ui-fieldset-legend {
    border: 1px solid #d8dee4;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    font-weight: 700;
    border-radius: 4px;
    user-select: none;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-fieldset .ui-fieldset-legend .ui-fieldset-toggler {
    display: inline-block;
    float: none;
    margin-right: .5rem
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-minusthick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-minusthick:before {
    content: ""
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-plusthick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-fieldset .ui-fieldset-legend .ui-icon-plusthick:before {
    content: ""
}

body .ui-fieldset .ui-fieldset-legend.ui-state-hover {
    outline: 0 none;
    border-color: #d8dee4;
    background: #e9ecef;
    color: #495057
}

body .ui-fieldset .ui-fieldset-legend.ui-state-hover .ui-icon {
    color: #495057
}

body .ui-fieldset .ui-fieldset-legend.ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-notificationbar {
    background: #fff;
    color: #495057;
    padding: 1rem;
    border: 1px solid #d8dee4
}

body .ui-panel {
    padding: 0
}

body .ui-panel .ui-panel-title {
    margin: 0
}

body .ui-panel .ui-panel-titlebar {
    border: 1px solid #d8dee4;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    font-weight: 700;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
    margin: 0;
    width: 2rem;
    height: 2rem;
    background: rgba(0, 0, 0, 0);
    color: #288d2c;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    border-radius: 50%;
    border: 0 none;
    text-align: center;
    line-height: 2rem;
    padding: 0;
    user-select: none;
    margin-top: -0.25rem;
    margin-bottom: -0.5rem
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
    background: #e9ecef;
    color: #495057;
    border-color: rgba(0, 0, 0, 0)
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #b3cbee
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-closethick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-closethick:before {
    content: ""
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-minusthick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-minusthick:before {
    content: ""
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-plusthick {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-plusthick:before {
    content: ""
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-gear {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon .ui-icon-gear:before {
    content: ""
}

body .ui-panel .ui-panel-titlebar+.ui-panel-content {
    border-top: 0 none
}

body .ui-panel .ui-panel-content {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-panel .ui-panel-footer {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem;
    margin: 0;
    border-top: 0 none
}

body .ui-panel>div:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-panel.ui-panel-collapsed .ui-panel-titlebar {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-panel.ui-panel-collapsed-h .ui-panel-titlebar {
    position: relative
}

body .ui-panel.ui-panel-collapsed-h .ui-panel-titlebar .ui-panel-titlebar-icon {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.5rem;
    margin-top: -0.5rem
}

body .ui-panelgrid .ui-panelgrid-cell {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-panelgrid .ui-panelgrid-cell.ui-widget-header {
    border: 1px solid #d8dee4;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    font-weight: 700
}

body .ui-scrollpanel {
    border: 1px solid #d8dee4;
    background: #fff;
    color: #495057;
    padding: 1rem
}

body .ui-scrollpanel .ui-scrollpanel-drag {
    background: #dadada
}

body .ui-scrollpanel .ui-scrollpanel-track {
    background: #f8f8f8;
    border: 0 none
}

body .ui-scrollpanel .ui-scrollpanel-corner {
    background: #f8f8f8
}

body .ui-splitter {
    border: 1px solid #d8dee4;
    background: #fff;
    border-radius: 4px;
    color: #495057
}

body .ui-splitter .ui-splitter-gutter {
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s;
    background: #f8f9fa
}

body .ui-splitter .ui-splitter-gutter .ui-splitter-gutter-handle {
    background: #d8dee4
}

body .ui-splitter .ui-splitter-gutter-resizing {
    background: #d8dee4
}

body .ui-tabs {
    padding: 0;
    position: static
}

body .ui-tabs .ui-tabs-nav {
    margin: 0;
    padding: 0;
    background: #fff;
    display: -ms-flexbox;
    display: flex
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header {
    list-style: none;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    float: none;
    padding: 1rem;
    background: #fff;
    color: #288d2c;
    margin: 0;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header a {
    color: #288d2c;
    padding: 0;
    font-weight: 700;
    display: block;
    line-height: 1;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header .ui-icon {
    position: static
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header .ui-icon.ui-icon-close {
    margin: 0;
    float: none;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none;
    margin-left: .5rem
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header .ui-icon.ui-icon-close:before {
    content: ""
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    background: #fff
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-hover a {
    color: #288d2c
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-hover .ui-icon {
    color: #288d2c
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-active {
    background: #fff
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-active a {
    color: #288d2c
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-active .ui-icon {
    color: #288d2c
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-error a {
    color: #f44336
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-error .ui-icon {
    color: #f44336
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-tabs-outline {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .2rem #b3cbee
}

body .ui-tabs .ui-tabs-nav li.ui-tabs-actions {
    order: 1;
    margin-left: auto
}

body .ui-tabs .ui-tabs-panels {
    border: 0 none;
    background: #fff;
    color: #495057;
    padding: 0;
    margin: 0;
    position: static;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

body .ui-tabs .ui-tabs-panels .ui-tabs-panel {
    padding: 1rem
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav {
    margin: 0;
    padding: 0;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav:before {
    content: "";
    position: absolute;
    display: block;
    border-bottom: 2px solid #d8dee4;
    left: 0;
    bottom: 0;
    width: 100%
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav li.ui-tabs-header {
    top: 0;
    border-bottom: 2px solid #d8dee4
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #288d2c
}

body .ui-tabs.ui-tabs-top>.ui-tabs-nav li.ui-tabs-header.ui-state-error {
    border-color: #f44336
}

body .ui-tabs.ui-tabs-top>.ui-tabs-panels {
    border-width: 1px 0 0 0
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav {
    margin: 0;
    padding: 0;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav:before {
    content: "";
    position: absolute;
    display: block;
    border-top: 2px solid #d8dee4;
    left: 0;
    top: 0;
    width: 100%
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav li.ui-tabs-header {
    top: 0;
    border-top: 2px solid #d8dee4
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #288d2c
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-nav li.ui-tabs-header.ui-state-error {
    border-color: #f44336
}

body .ui-tabs.ui-tabs-bottom>.ui-tabs-panels {
    border-width: 1px 0 0 0
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav,
body .ui-tabs.ui-tabs-right>.ui-tabs-nav {
    width: 25%;
    margin: 0;
    padding: 0;
    height: auto;
    -ms-flex-direction: column;
    flex-direction: column
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header,
body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header {
    width: 100%
}

body .ui-tabs.ui-tabs-left>.ui-tabs-panels,
body .ui-tabs.ui-tabs-right>.ui-tabs-panels {
    width: 75%
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav {
    margin: 0;
    padding: 0;
    border-left: 2px solid #d8dee4
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header {
    top: 0;
    border-left: 2px solid #d8dee4;
    left: -2px
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #288d2c
}

body .ui-tabs.ui-tabs-right>.ui-tabs-nav li.ui-tabs-header.ui-state-error {
    border-color: #f44336
}

body .ui-tabs.ui-tabs-right>.ui-tabs-panels {
    border-width: 1px 0 0 0
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav {
    margin: 0;
    padding: 0;
    right: -2px
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header {
    top: 0;
    border-right: 2px solid #d8dee4;
    margin-right: -2px
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header.ui-state-hover {
    border-color: #adb5bd
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header.ui-state-active {
    border-color: #288d2c
}

body .ui-tabs.ui-tabs-left>.ui-tabs-nav li.ui-tabs-header.ui-state-error {
    border-color: #f44336
}

body .ui-tabs.ui-tabs-left>.ui-tabs-panels {
    border-width: 1px 0 0 0
}

body .ui-tabs .ui-tabs-navscroller {
    position: relative
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn {
    border: 0 none;
    background: #fff;
    color: #288d2c;
    outline: 0 none;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s, opacity .2s
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-left {
    left: 0;
    height: 100%
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-left .ui-icon {
    margin: 0;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-left .ui-icon:before {
    content: ""
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-right {
    right: 0;
    height: 100%
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-right .ui-icon {
    margin: 0;
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-tabs-navscroller-btn-right .ui-icon:before {
    content: ""
}

body .ui-tabs .ui-tabs-navscroller .ui-tabs-navscroller-btn.ui-state-hover {
    background: #e9ecef;
    color: #495057
}

body .ui-toolbar {
    border: 1px solid #d8dee4;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    border-radius: 4px
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title {
    border: 1px solid #d8dee4;
    background: #f8f9fa;
    color: #495057;
    padding: 1rem;
    margin: 0;
    border-right: 0 none;
    border-radius: 0
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #d8dee4
}

body .ui-wizard .ui-wizard-step-titles .ui-wizard-step-title.ui-state-highlight {
    background: #e6eef9;
    color: #495057
}

body .ui-wizard .ui-icon-arrowthick-1-w {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-wizard .ui-icon-arrowthick-1-w:before {
    content: ""
}

body .ui-wizard .ui-icon-arrowthick-1-e {
    font-family: "primeicons" !important;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    background: none
}

body .ui-wizard .ui-icon-arrowthick-1-e:before {
    content: ""
}