.primary {
    color: #288d2c;
}
.primary-bg {
    background-color: #F1F5F9;
}
.fn-h4 {
    font-family: Inter;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0em;
}
.fn-h3 {
    font-family: Inter;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0em;
}
.fn-h2 {
    font-family: Inter;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0em;
}
.fn-h1{
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
}
.pgh-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
}
.pgh {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}
.text-center {
    text-align: center;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500; 
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}

/* customStyles.css */
.p-datatable-tbody > tr {
    height: 25px; /* Adjust the height as needed */
  }
  
  .p-datatable-tbody > tr > td {
    padding: 5px; /* Adjust the padding as needed to fit the row height */
  }

  .p-datatable-tbody > tr >t {
    height: 10px; /* Adjust the height as needed */
  }
  
  .p-datatable-tbody > th > td {
    padding: 5px; /* Adjust the padding as needed to fit the row height */
  }

  .p-calendar .p-inputtext {
    font-size: 16px;
    font-weight: 700;
    color:#101010
}

.p-dropdown .p-inputtext {
    font-size: 16px;
    font-weight: 700;
    color:#101010
}