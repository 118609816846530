.p-radiobutton .p-radiobutton-box{
    width:16px !important;
    height:16px !important;
}

.p-inputtext::placeholder {
  color: #CBD5E1 !important; /* Change to your desired color */
}

/* Change placeholder color for a specific InputText component */
.custom-input::placeholder {
  color: #CBD5E1 !important; /* Change to your desired color */
}

.p-datepicker{
  z-index: 2000!important;
}

.p-dropdown-panel {
  z-index: 2000!important; /* Adjust the value as needed */
}